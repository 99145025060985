/*
 * CSSMap plugin - Poland [ http://cssmapsplugin.com/get/poland ]
 * version: 5.5
 *
 * author: Łukasz Popardowski { Winston_Wolf }
 * license: http://cssmapsplugin.com/license
 * FAQ: http://cssmapsplugin.com/faq
 *
 * email: http://cssmapsplugin.com/contact
 * twitter: @CSSMapplugin

 * ---------------------------------------------------------------------------------------------------- *
 *                               DO NOT EDIT FROM THIS POINT!                                           *
 * ---------------------------------------------------------------------------------------------------- */
@import url("cssmap-themes.css");

.pl1 .s1 {
    height: .2em;
    left: 4.1em;
    top: 12em;
    width: .4em
}

.pl1 .s2 {
    height: .1em;
    left: 4.1em;
    top: 11.9em;
    width: .5em
}

.pl1 .s3 {
    height: .1em;
    left: 4.1em;
    top: 11.8em;
    width: .8em
}

.pl1 .s4 {
    height: .1em;
    left: 4em;
    top: 11.7em;
    width: .9em
}

.pl1 .s5 {
    height: .2em;
    left: 3.9em;
    top: 11.5em;
    width: .9em
}

.pl1 .s6 {
    height: .2em;
    left: 3.6em;
    top: 11.3em;
    width: .1em
}

.pl1 .s7 {
    height: .3em;
    left: 3.7em;
    top: 11.2em;
    width: 1em
}

.pl1 .s8 {
    height: .2em;
    left: 3.9em;
    top: 11.1em;
    width: 1em
}

.pl1 .s9 {
    height: .1em;
    left: 3.9em;
    top: 11em;
    width: 1.1em
}

.pl1 .s10 {
    height: .1em;
    left: 3.3em;
    top: 10.9em;
    width: 1.8em
}

.pl1 .s11 {
    height: .1em;
    left: 3.3em;
    top: 10.8em;
    width: 1.9em
}

.pl1 .s12 {
    height: .1em;
    left: 3em;
    top: 10.7em;
    width: 2.2em
}

.pl1 .s13 {
    height: .1em;
    left: 3.3em;
    top: 8.1em;
    width: .1em
}

.pl1 .s14 {
    height: .1em;
    left: 3.2em;
    top: 8.2em;
    width: .4em
}

.pl1 .s15 {
    height: .1em;
    left: 3.1em;
    top: 8.3em;
    width: .6em
}

.pl1 .s16 {
    height: .1em;
    left: 3.9em;
    top: 8.1em;
    width: .2em
}

.pl1 .s17 {
    height: .1em;
    left: 3.9em;
    top: 8.2em;
    width: .4em
}

.pl1 .s18 {
    height: .1em;
    left: 3.8em;
    top: 8.3em;
    width: .5em
}

.pl1 .s19 {
    height: .1em;
    left: 3.1em;
    top: 8.4em;
    width: 1.2em
}

.pl1 .s20 {
    height: .1em;
    left: 3em;
    top: 8.5em;
    width: 1.5em
}

.pl1 .s21 {
    height: .1em;
    left: 3em;
    top: 8.6em;
    width: 1.7em
}

.pl1 .s22 {
    height: .1em;
    left: 5.9em;
    top: 9.2em;
    width: .1em
}

.pl1 .s23 {
    height: .2em;
    left: 5.9em;
    top: 9.4em;
    width: .1em
}

.pl1 .s24 {
    height: .6em;
    left: 5.7em;
    top: 9em;
    width: .2em
}

.pl1 .s25 {
    height: .9em;
    left: 5.6em;
    top: 9em;
    width: .1em
}

.pl1 .s26 {
    height: .2em;
    left: 5.6em;
    top: 8.6em;
    width: .1em
}

.pl1 .s27 {
    height: .2em;
    left: 5.1em;
    top: 8.5em;
    width: .5em
}

.pl1 .s28 {
    height: 1.9em;
    left: 5em;
    top: 8.6em;
    width: .3em
}

.pl1 .s29 {
    height: .3em;
    left: 2.7em;
    top: 10.4em;
    width: 2.5em
}

.pl1 .s30 {
    height: .2em;
    left: 1.5em;
    top: 10.2em;
    width: .1em
}

.pl1 .s31 {
    height: .4em;
    left: 1.6em;
    top: 10em;
    width: .1em
}

.pl1 .s32 {
    height: .7em;
    left: 1.7em;
    top: 9.7em;
    width: .1em
}

.pl1 .s33 {
    height: 1.3em;
    left: 1.8em;
    top: 9.1em;
    width: .1em
}

.pl1 .s34 {
    height: 1.4em;
    left: 1.9em;
    top: 9em;
    width: .4em
}

.pl1 .s35 {
    height: 1.4em;
    left: 2.9em;
    top: 8.7em;
    width: 2.7em
}

.pl1 .s36 {
    height: 1.4em;
    left: 2.8em;
    top: 8.8em;
    width: 2.7em
}

.pl1 .s37 {
    height: 1.8em;
    left: 2.4em;
    top: 8.8em;
    width: .3em
}

.pl1 .s38 {
    height: 1.5em;
    left: 2.3em;
    top: 8.9em;
    width: 3.1em
}

.pl2 .s1 {
    height: .1em;
    left: 8.9em;
    top: 4.7em;
    width: .1em
}

.pl2 .s2 {
    height: .1em;
    left: 6.4em;
    top: 3.2em;
    width: .2em
}

.pl2 .s3 {
    height: .1em;
    left: 6.1em;
    top: 3.3em;
    width: .6em
}

.pl2 .s4 {
    height: .1em;
    left: 7.3em;
    top: 3.4em;
    width: .2em
}

.pl2 .s5 {
    height: .1em;
    left: 5.9em;
    top: 3.4em;
    width: 1.2em
}

.pl2 .s6 {
    height: .1em;
    left: 5.9em;
    top: 3.5em;
    width: 1.5em
}

.pl2 .s7 {
    height: .1em;
    left: 8.3em;
    top: 4.1em;
    width: .1em
}

.pl2 .s8 {
    height: .3em;
    left: 8.1em;
    top: 3.9em;
    width: .1em
}

.pl2 .s9 {
    height: .1em;
    left: 5.9em;
    top: 3.6em;
    width: 1.7em
}

.pl2 .s10 {
    height: .2em;
    left: 5.2em;
    top: 4.1em;
    width: .1em
}

.pl2 .s11 {
    height: .3em;
    left: 5.3em;
    top: 4em;
    width: .1em
}

.pl2 .s12 {
    height: .2em;
    left: 5.3em;
    top: 4.6em;
    width: .1em
}

.pl2 .s13 {
    height: .3em;
    left: 5.3em;
    top: 4.9em;
    width: .1em
}

.pl2 .s14 {
    height: .7em;
    left: 5.4em;
    top: 4.6em;
    width: .1em
}

.pl2 .s15 {
    height: .7em;
    left: 5.4em;
    top: 3.7em;
    width: .1em
}

.pl2 .s16 {
    height: 1.7em;
    left: 5.5em;
    top: 3.6em;
    width: .1em
}

.pl2 .s17 {
    height: .6em;
    left: 8.8em;
    top: 4.3em;
    width: .1em
}

.pl2 .s18 {
    height: .9em;
    left: 8.7em;
    top: 4.3em;
    width: .1em
}

.pl2 .s19 {
    height: 1em;
    left: 8.5em;
    top: 4.3em;
    width: .2em
}

.pl2 .s20 {
    height: .1em;
    left: 7.8em;
    top: 6.7em;
    width: .4em
}

.pl2 .s21 {
    height: .1em;
    left: 7.4em;
    top: 6.7em;
    width: .2em
}

.pl2 .s22 {
    height: .1em;
    left: 7.4em;
    top: 6.6em;
    width: .8em
}

.pl2 .s23 {
    height: .1em;
    left: 7.3em;
    top: 6.5em;
    width: .9em
}

.pl2 .s24 {
    height: .1em;
    left: 7.1em;
    top: 6.4em;
    width: 1.2em
}

.pl2 .s25 {
    height: .1em;
    left: 6.9em;
    top: 6.4em;
    width: .1em
}

.pl2 .s26 {
    height: .1em;
    left: 6.9em;
    top: 6.3em;
    width: 1.5em
}

.pl2 .s27 {
    height: .1em;
    left: 6.5em;
    top: 6.3em;
    width: .3em
}

.pl2 .s28 {
    height: .1em;
    left: 6.3em;
    top: 6.2em;
    width: 2em
}

.pl2 .s29 {
    height: .1em;
    left: 6.2em;
    top: 6.1em;
    width: 2.2em
}

.pl2 .s30 {
    height: .1em;
    left: 8.5em;
    top: 5.4em;
    width: .1em
}

.pl2 .s31 {
    height: .2em;
    left: 8.5em;
    top: 5.7em;
    width: .1em
}

.pl2 .s32 {
    height: .1em;
    left: 5.4em;
    top: 5.7em;
    width: .1em
}

.pl2 .s33 {
    height: .3em;
    left: 5.5em;
    top: 5.7em;
    width: .1em
}

.pl2 .s34 {
    height: .1em;
    left: 5.7em;
    top: 6em;
    width: .2em
}

.pl2 .s35 {
    height: .1em;
    left: 6.1em;
    top: 6em;
    width: 2.3em
}

.pl2 .s36 {
    height: 1.8em;
    left: 5.8em;
    top: 4.2em;
    width: 2.7em
}

.pl2 .s37 {
    height: 2.2em;
    left: 5.6em;
    top: 3.7em;
    width: 2.5em
}

.pl3 .s1 {
    height: .1em;
    left: 14em;
    top: 6.9em;
    width: .2em
}

.pl3 .s2 {
    height: .1em;
    left: 13.9em;
    top: 7em;
    width: .4em
}

.pl3 .s3 {
    height: .1em;
    left: 13.9em;
    top: 7.1em;
    width: .6em
}

.pl3 .s4 {
    height: .1em;
    left: 13.7em;
    top: 7.2em;
    width: .9em
}

.pl3 .s5 {
    height: .4em;
    left: 14.8em;
    top: 7.4em;
    width: .1em
}

.pl3 .s6 {
    height: .1em;
    left: 13.7em;
    top: 7.3em;
    width: 1.1em
}

.pl3 .s7 {
    height: .8em;
    left: 13.3em;
    top: 7.4em;
    width: 1.5em
}

.pl3 .s8 {
    height: 1.1em;
    left: 13em;
    top: 7.5em;
    width: 1.7em
}

.pl3 .s9 {
    height: .4em;
    left: 14.8em;
    top: 8.8em;
    width: .1em
}

.pl3 .s10 {
    height: .6em;
    left: 15.4em;
    top: 10.7em;
    width: .1em
}

.pl3 .s11 {
    height: .3em;
    left: 15.4em;
    top: 10.2em;
    width: .1em
}

.pl3 .s12 {
    height: 1.3em;
    left: 15.3em;
    top: 10.1em;
    width: .1em
}

.pl3 .s13 {
    height: 1.5em;
    left: 15.2em;
    top: 10em;
    width: .1em
}

.pl3 .s14 {
    height: 1.7em;
    left: 15.1em;
    top: 9.8em;
    width: .1em
}

.pl3 .s15 {
    height: 1.9em;
    left: 15em;
    top: 9.6em;
    width: .1em
}

.pl3 .s16 {
    height: .1em;
    left: 14.6em;
    top: 11.7em;
    width: .1em
}

.pl3 .s17 {
    height: .1em;
    left: 14.4em;
    top: 11.6em;
    width: .4em
}

.pl3 .s18 {
    height: .1em;
    left: 14.4em;
    top: 11.5em;
    width: .5em
}

.pl3 .s19 {
    height: .1em;
    left: 13.2em;
    top: 11.6em;
    width: .7em
}

.pl3 .s20 {
    height: .1em;
    left: 12.9em;
    top: 11.4em;
    width: .1em
}

.pl3 .s21 {
    height: .3em;
    left: 13em;
    top: 11.3em;
    width: 1em
}

.pl3 .s22 {
    height: 2em;
    left: 13.1em;
    top: 9.5em;
    width: 1.9em
}

.pl3 .s23 {
    height: 1.7em;
    left: 12.8em;
    top: 9.3em;
    width: 2.1em
}

.pl3 .s24 {
    height: 2.1em;
    left: 12.7em;
    top: 8.8em;
    width: 2.1em
}

.pl3 .s25 {
    height: 1.1em;
    left: 12em;
    top: 9.2em;
    width: .1em
}

.pl3 .s26 {
    height: .1em;
    left: 12em;
    top: 9em;
    width: .1em
}

.pl3 .s27 {
    height: .2em;
    left: 11.8em;
    top: 8.5em;
    width: .2em
}

.pl3 .s28 {
    height: .4em;
    left: 12em;
    top: 8.4em;
    width: .1em
}

.pl3 .s29 {
    height: .1em;
    left: 12.2em;
    top: 10.5em;
    width: .1em
}

.pl3 .s30 {
    height: 2.5em;
    left: 12.1em;
    top: 8.1em;
    width: .1em
}

.pl3 .s31 {
    height: .1em;
    left: 12.1em;
    top: 7.9em;
    width: .1em
}

.pl3 .s32 {
    height: .1em;
    left: 12.1em;
    top: 7.7em;
    width: .1em
}

.pl3 .s33 {
    height: 2em;
    left: 12.5em;
    top: 8.6em;
    width: 2.2em
}

.pl3 .s34 {
    height: 2.9em;
    left: 12.2em;
    top: 7.6em;
    width: .4em
}

.pl3 .s35 {
    height: 3.2em;
    left: 12.6em;
    top: 7.6em;
    width: 2em
}

.pl4 .s1 {
    height: .1em;
    left: 3.1em;
    top: 4.8em;
    width: .1em
}

.pl4 .s2 {
    height: .3em;
    left: 3em;
    top: 4.9em;
    width: .3em
}

.pl4 .s3 {
    height: .1em;
    left: 2.6em;
    top: 5.1em;
    width: .1em
}

.pl4 .s4 {
    height: .1em;
    left: 2.4em;
    top: 5.2em;
    width: .9em
}

.pl4 .s5 {
    height: .1em;
    left: 2.3em;
    top: 5.3em;
    width: 1em
}

.pl4 .s6 {
    height: .1em;
    left: 2.2em;
    top: 5.4em;
    width: 1.1em
}

.pl4 .s7 {
    height: .1em;
    left: 2em;
    top: 5.5em;
    width: 1.3em
}

.pl4 .s8 {
    height: .1em;
    left: 1.7em;
    top: 5.5em;
    width: .2em
}

.pl4 .s9 {
    height: .3em;
    left: 1.6em;
    top: 5.6em;
    width: 1.6em
}

.pl4 .s10 {
    height: .1em;
    left: 3.1em;
    top: 6.4em;
    width: .1em
}

.pl4 .s11 {
    height: .3em;
    left: 3.1em;
    top: 6.7em;
    width: .1em
}

.pl4 .s12 {
    height: .4em;
    left: 1.1em;
    top: 6.5em;
    width: .1em
}

.pl4 .s13 {
    height: 1em;
    left: 1.2em;
    top: 6em;
    width: .1em
}

.pl4 .s14 {
    height: 1.4em;
    left: 1.3em;
    top: 6em;
    width: .1em
}

.pl4 .s15 {
    height: .1em;
    left: 3.1em;
    top: 7.2em;
    width: .1em
}

.pl4 .s16 {
    height: .3em;
    left: 1.2em;
    top: 7.9em;
    width: .1em
}

.pl4 .s17 {
    height: .7em;
    left: 1.3em;
    top: 7.7em;
    width: .1em
}

.pl4 .s18 {
    height: .1em;
    left: 1.7em;
    top: 9em;
    width: .2em
}

.pl4 .s19 {
    height: .1em;
    left: 1.7em;
    top: 8.9em;
    width: .6em
}

.pl4 .s20 {
    height: .1em;
    left: 1.5em;
    top: 8.8em;
    width: .9em
}

.pl4 .s21 {
    height: .8em;
    left: 3.2em;
    top: 7.4em;
    width: .1em
}

.pl4 .s22 {
    height: .9em;
    left: 3.1em;
    top: 7.4em;
    width: .1em
}

.pl4 .s23 {
    height: 2.3em;
    left: 3em;
    top: 6.2em;
    width: .1em
}

.pl4 .s24 {
    height: .1em;
    left: 2.7em;
    top: 8.8em;
    width: .1em
}

.pl4 .s25 {
    height: 3em;
    left: 1.5em;
    top: 5.7em;
    width: 1.5em
}

.pl4 .s26 {
    height: 2.8em;
    left: 1.4em;
    top: 6em;
    width: 1.5em
}

.pl4 .s27 {
    height: .5em;
    left: 3.3em;
    top: 7.6em;
    width: .2em
}

.pl4 .s28 {
    height: .3em;
    left: 3.4em;
    top: 7.9em;
    width: .2em
}

.pl4 .s29 {
    height: .4em;
    left: 3.6em;
    top: 7.9em;
    width: .3em
}

.pl4 .s30 {
    height: .1em;
    left: 3.7em;
    top: 8.3em;
    width: .1em
}

.pl5 .s1 {
    height: .3em;
    left: 10em;
    top: 8.8em;
    width: .1em
}

.pl5 .s2 {
    height: .7em;
    left: 9.9em;
    top: 8.5em;
    width: .1em
}

.pl5 .s3 {
    height: .3em;
    left: 10.2em;
    top: 8.2em;
    width: .1em
}

.pl5 .s4 {
    height: .5em;
    left: 10.1em;
    top: 7.9em;
    width: .1em
}

.pl5 .s5 {
    height: .6em;
    left: 9.7em;
    top: 7.8em;
    width: .4em
}

.pl5 .s6 {
    height: .2em;
    left: 9.6em;
    top: 7.3em;
    width: .1em
}

.pl5 .s7 {
    height: .2em;
    left: 9.4em;
    top: 7.2em;
    width: .2em
}

.pl5 .s8 {
    height: 1.9em;
    left: 9.6em;
    top: 7.6em;
    width: .1em
}

.pl5 .s9 {
    height: .1em;
    left: 8.8em;
    top: 10.3em;
    width: .2em
}

.pl5 .s10 {
    height: .1em;
    left: 8.5em;
    top: 10.2em;
    width: .5em
}

.pl5 .s11 {
    height: .1em;
    left: 8.5em;
    top: 10.1em;
    width: .6em
}

.pl5 .s12 {
    height: .1em;
    left: 8.4em;
    top: 10em;
    width: .7em
}

.pl5 .s13 {
    height: .1em;
    left: 8.1em;
    top: 10em;
    width: .1em
}

.pl5 .s14 {
    height: .1em;
    left: 8em;
    top: 9.9em;
    width: 1.1em
}

.pl5 .s15 {
    height: .1em;
    left: 9.3em;
    top: 10em;
    width: .1em
}

.pl5 .s16 {
    height: .1em;
    left: 9.2em;
    top: 9.9em;
    width: .2em
}

.pl5 .s17 {
    height: .1em;
    left: 7.8em;
    top: 9.8em;
    width: 1.5em
}

.pl5 .s18 {
    height: .1em;
    left: 7.4em;
    top: 7.4em;
    width: .1em
}

.pl5 .s19 {
    height: .2em;
    left: 7.2em;
    top: 9.7em;
    width: .2em
}

.pl5 .s20 {
    height: .1em;
    left: 7em;
    top: 9.7em;
    width: .1em
}

.pl5 .s21 {
    height: .1em;
    left: 6.4em;
    top: 9.2em;
    width: .1em
}

.pl5 .s22 {
    height: .3em;
    left: 6.5em;
    top: 9.1em;
    width: .1em
}

.pl5 .s23 {
    height: .7em;
    left: 6.6em;
    top: 9em;
    width: .2em
}

.pl5 .s24 {
    height: .8em;
    left: 6.8em;
    top: 8.9em;
    width: .1em
}

.pl5 .s25 {
    height: 1.5em;
    left: 6.9em;
    top: 8.2em;
    width: .5em
}

.pl5 .s26 {
    height: 1.4em;
    left: 7em;
    top: 8em;
    width: 2.9em
}

.pl5 .s27 {
    height: 2.2em;
    left: 7.6em;
    top: 7.4em;
    width: 2em
}

.pl5 .s28 {
    height: 2.4em;
    left: 7.4em;
    top: 7.5em;
    width: .3em
}

.pl5 .s29 {
    height: .1em;
    left: 8.2em;
    top: 6.7em;
    width: .1em
}

.pl5 .s30 {
    height: .1em;
    left: 7.9em;
    top: 6.8em;
    width: .7em
}

.pl5 .s31 {
    height: .1em;
    left: 8em;
    top: 6.9em;
    width: .8em
}

.pl5 .s32 {
    height: .1em;
    left: 9em;
    top: 6.9em;
    width: .3em
}

.pl5 .s33 {
    height: .4em;
    left: 7.9em;
    top: 7em;
    width: 1.5em
}

.pl5 .s34 {
    height: 2.7em;
    left: 7.7em;
    top: 7.1em;
    width: 1.6em
}

.pl6 .s1 {
    height: .6em;
    left: 11.3em;
    top: 13.1em;
    width: .1em
}

.pl6 .s2 {
    height: .7em;
    left: 11.2em;
    top: 13em;
    width: .1em
}

.pl6 .s3 {
    height: .2em;
    left: 11.2em;
    top: 12.7em;
    width: .1em
}

.pl6 .s4 {
    height: 1.3em;
    left: 11.1em;
    top: 12.5em;
    width: .1em
}

.pl6 .s5 {
    height: .1em;
    left: 10.7em;
    top: 11.6em;
    width: .5em
}

.pl6 .s6 {
    height: .1em;
    left: 10.5em;
    top: 11.7em;
    width: .6em
}

.pl6 .s7 {
    height: .1em;
    left: 10.4em;
    top: 11.8em;
    width: .7em
}

.pl6 .s8 {
    height: .1em;
    left: 10.5em;
    top: 13.9em;
    width: .4em
}

.pl6 .s9 {
    height: .2em;
    left: 8.9em;
    top: 14.1em;
    width: .6em
}

.pl6 .s10 {
    height: .2em;
    left: 9em;
    top: 13.9em;
    width: .6em
}

.pl6 .s11 {
    height: .1em;
    left: 8.9em;
    top: 13.8em;
    width: 2.1em
}

.pl6 .s12 {
    height: .3em;
    left: 8em;
    top: 12.2em;
    width: .1em
}

.pl6 .s13 {
    height: .6em;
    left: 8.1em;
    top: 12.1em;
    width: .1em
}

.pl6 .s14 {
    height: .9em;
    left: 8.2em;
    top: 12em;
    width: .1em
}

.pl6 .s15 {
    height: 1em;
    left: 8.3em;
    top: 11.9em;
    width: .1em
}

.pl6 .s16 {
    height: .1em;
    left: 8.3em;
    top: 11.7em;
    width: .1em
}

.pl6 .s17 {
    height: .1em;
    left: 8.4em;
    top: 13em;
    width: .1em
}

.pl6 .s18 {
    height: 1.3em;
    left: 8.4em;
    top: 11.6em;
    width: .1em
}

.pl6 .s19 {
    height: 1.9em;
    left: 8.5em;
    top: 11.5em;
    width: .1em
}

.pl6 .s20 {
    height: .1em;
    left: 9.3em;
    top: 11.2em;
    width: .4em
}

.pl6 .s21 {
    height: .1em;
    left: 8.9em;
    top: 11.3em;
    width: .8em
}

.pl6 .s22 {
    height: .4em;
    left: 8.6em;
    top: 11.4em;
    width: 1.2em
}

.pl6 .s23 {
    height: 1.9em;
    left: 8.6em;
    top: 11.8em;
    width: 1.3em
}

.pl6 .s24 {
    height: 1.9em;
    left: 8.7em;
    top: 11.9em;
    width: 2.4em
}

.pl7 .s1 {
    height: .1em;
    left: 11.6em;
    top: 3.9em;
    width: .1em
}

.pl7 .s2 {
    height: .1em;
    left: 11.5em;
    top: 4em;
    width: .2em
}

.pl7 .s3 {
    height: .1em;
    left: 11.1em;
    top: 4.1em;
    width: .7em
}

.pl7 .s4 {
    height: .1em;
    left: 11em;
    top: 4.2em;
    width: .8em
}

.pl7 .s5 {
    height: .1em;
    left: 10.7em;
    top: 4.2em;
    width: .1em
}

.pl7 .s6 {
    height: .1em;
    left: 10.7em;
    top: 4.3em;
    width: 1.2em
}

.pl7 .s7 {
    height: .1em;
    left: 10.3em;
    top: 4.4em;
    width: 1.5em
}

.pl7 .s8 {
    height: .1em;
    left: 10.2em;
    top: 4.5em;
    width: 1.6em
}

.pl7 .s9 {
    height: .1em;
    left: 10em;
    top: 4.6em;
    width: 1.8em
}

.pl7 .s10 {
    height: .1em;
    left: 9.8em;
    top: 4.7em;
    width: 2.1em
}

.pl7 .s11 {
    height: .1em;
    left: 9.4em;
    top: 4.7em;
    width: .1em
}

.pl7 .s12 {
    height: .2em;
    left: 9.1em;
    top: 4.6em;
    width: .1em
}

.pl7 .s13 {
    height: .1em;
    left: 12.6em;
    top: 5.4em;
    width: .2em
}

.pl7 .s14 {
    height: .1em;
    left: 13.9em;
    top: 6.9em;
    width: .1em
}

.pl7 .s15 {
    height: .4em;
    left: 13.8em;
    top: 6.8em;
    width: .1em
}

.pl7 .s16 {
    height: .5em;
    left: 13.7em;
    top: 6.7em;
    width: .1em
}

.pl7 .s17 {
    height: .7em;
    left: 13.3em;
    top: 6.7em;
    width: .4em
}

.pl7 .s18 {
    height: .8em;
    left: 13.2em;
    top: 6.7em;
    width: .1em
}

.pl7 .s19 {
    height: 1em;
    left: 13.1em;
    top: 6.5em;
    width: .1em
}

.pl7 .s20 {
    height: 1.3em;
    left: 13em;
    top: 6.2em;
    width: .1em
}

.pl7 .s21 {
    height: .2em;
    left: 8.2em;
    top: 6.5em;
    width: .1em
}

.pl7 .s22 {
    height: .4em;
    left: 8.3em;
    top: 6.4em;
    width: .1em
}

.pl7 .s23 {
    height: .1em;
    left: 8.3em;
    top: 6.2em;
    width: .1em
}

.pl7 .s24 {
    height: .8em;
    left: 8.4em;
    top: 6em;
    width: .1em
}

.pl7 .s25 {
    height: .9em;
    left: 8.5em;
    top: 5.9em;
    width: .1em
}

.pl7 .s26 {
    height: .2em;
    left: 8.5em;
    top: 5.5em;
    width: 4.2em
}

.pl7 .s27 {
    height: .1em;
    left: 8.5em;
    top: 5.3em;
    width: .1em
}

.pl7 .s28 {
    height: 1.6em;
    left: 8.6em;
    top: 5.3em;
    width: .1em
}

.pl7 .s29 {
    height: 1.7em;
    left: 8.7em;
    top: 5.2em;
    width: .1em
}

.pl7 .s30 {
    height: .1em;
    left: 8.8em;
    top: 6.9em;
    width: .2em
}

.pl7 .s31 {
    height: .1em;
    left: 11.4em;
    top: 9.9em;
    width: .2em
}

.pl7 .s32 {
    height: .1em;
    left: 11.3em;
    top: 9.8em;
    width: .7em
}

.pl7 .s33 {
    height: .2em;
    left: 11em;
    top: 9.6em;
    width: 1em
}

.pl7 .s34 {
    height: .1em;
    left: 10.8em;
    top: 9.6em;
    width: .1em
}

.pl7 .s35 {
    height: .1em;
    left: 10.3em;
    top: 9.6em;
    width: .3em
}

.pl7 .s36 {
    height: .1em;
    left: 10.3em;
    top: 9.5em;
    width: 1.7em
}

.pl7 .s37 {
    height: .1em;
    left: 10em;
    top: 9.1em;
    width: 2.1em
}

.pl7 .s38 {
    height: .2em;
    left: 12em;
    top: 8.8em;
    width: .1em
}

.pl7 .s39 {
    height: .8em;
    left: 10.1em;
    top: 8.7em;
    width: 1.9em
}

.pl7 .s40 {
    height: .3em;
    left: 10em;
    top: 8.5em;
    width: 1.8em
}

.pl7 .s41 {
    height: .1em;
    left: 9.9em;
    top: 8.4em;
    width: .3em
}

.pl7 .s42 {
    height: .1em;
    left: 10.3em;
    top: 8.4em;
    width: 1.7em
}

.pl7 .s43 {
    height: .2em;
    left: 10.3em;
    top: 8.2em;
    width: 1.8em
}

.pl7 .s44 {
    height: .1em;
    left: 12.1em;
    top: 8em;
    width: .1em
}

.pl7 .s45 {
    height: .3em;
    left: 10.2em;
    top: 7.9em;
    width: 1.9em
}

.pl7 .s46 {
    height: .1em;
    left: 10.1em;
    top: 7.8em;
    width: 2.1em
}

.pl7 .s47 {
    height: .1em;
    left: 9.7em;
    top: 7.7em;
    width: 2.4em
}

.pl7 .s48 {
    height: .1em;
    left: 9.6em;
    top: 7.5em;
    width: .1em
}

.pl7 .s49 {
    height: .1em;
    left: 9.7em;
    top: 7.6em;
    width: 2.5em
}

.pl7 .s50 {
    height: 1.9em;
    left: 9.7em;
    top: 5.7em;
    width: 3.3em
}

.pl7 .s51 {
    height: 1.9em;
    left: 9.6em;
    top: 5.4em;
    width: 2.8em
}

.pl7 .s52 {
    height: 2em;
    left: 9.4em;
    top: 5.2em;
    width: 2.9em
}

.pl7 .s53 {
    height: 2em;
    left: 9.3em;
    top: 5em;
    width: 2.9em
}

.pl7 .s54 {
    height: .1em;
    left: 8.9em;
    top: 4.8em;
    width: 3em
}

.pl7 .s55 {
    height: 2em;
    left: 8.8em;
    top: 4.9em;
    width: 3.3em
}

.pl8 .s1 {
    height: .1em;
    left: 6.4em;
    top: 9.6em;
    width: .2em
}

.pl8 .s2 {
    height: .1em;
    left: 7.1em;
    top: 9.7em;
    width: .1em
}

.pl8 .s3 {
    height: .1em;
    left: 6.3em;
    top: 9.7em;
    width: .7em
}

.pl8 .s4 {
    height: .2em;
    left: 5.7em;
    top: 9.6em;
    width: .4em
}

.pl8 .s5 {
    height: .1em;
    left: 5.7em;
    top: 9.8em;
    width: 1.5em
}

.pl8 .s6 {
    height: .2em;
    left: 5.6em;
    top: 9.9em;
    width: 1.7em
}

.pl8 .s7 {
    height: .1em;
    left: 5.5em;
    top: 10.1em;
    width: 1.7em
}

.pl8 .s8 {
    height: .2em;
    left: 5.4em;
    top: 10.2em;
    width: 1.9em
}

.pl8 .s9 {
    height: .1em;
    left: 7.1em;
    top: 10.6em;
    width: .1em
}

.pl8 .s10 {
    height: .1em;
    left: 5.3em;
    top: 10.4em;
    width: 1.9em
}

.pl8 .s11 {
    height: .4em;
    left: 5.2em;
    top: 10.5em;
    width: 1.9em
}

.pl8 .s12 {
    height: .1em;
    left: 6em;
    top: 12.4em;
    width: .3em
}

.pl8 .s13 {
    height: .2em;
    left: 5.9em;
    top: 12.2em;
    width: .5em
}

.pl8 .s14 {
    height: .1em;
    left: 5.8em;
    top: 12.1em;
    width: .6em
}

.pl8 .s15 {
    height: .1em;
    left: 5.7em;
    top: 12em;
    width: .7em
}

.pl8 .s16 {
    height: .1em;
    left: 5.7em;
    top: 11.9em;
    width: .9em
}

.pl8 .s17 {
    height: .1em;
    left: 5.9em;
    top: 11.8em;
    width: .9em
}

.pl8 .s18 {
    height: .1em;
    left: 5.3em;
    top: 11.7em;
    width: 1.7em
}

.pl8 .s19 {
    height: .1em;
    left: 5.2em;
    top: 11.6em;
    width: 1.7em
}

.pl8 .s20 {
    height: .1em;
    left: 5.1em;
    top: 11.5em;
    width: 1.8em
}

.pl8 .s21 {
    height: .2em;
    left: 5.1em;
    top: 10.9em;
    width: 2.1em
}

.pl8 .s22 {
    height: .3em;
    left: 5em;
    top: 11em;
    width: 2em
}

.pl8 .s23 {
    height: .4em;
    left: 4.9em;
    top: 11.1em;
    width: 2em
}

.pl8 .s24 {
    height: .1em;
    left: 4.7em;
    top: 11.3em;
    width: .2em
}

.pl9 .s1 {
    height: .3em;
    left: 13.5em;
    top: 14.4em;
    width: .2em
}

.pl9 .s2 {
    height: .9em;
    left: 13.2em;
    top: 13.7em;
    width: .3em
}

.pl9 .s3 {
    height: 1.2em;
    left: 12.9em;
    top: 13.3em;
    width: .5em
}

.pl9 .s4 {
    height: .2em;
    left: 13.2em;
    top: 13.1em;
    width: .3em
}

.pl9 .s5 {
    height: .1em;
    left: 13.2em;
    top: 13em;
    width: .4em
}

.pl9 .s6 {
    height: .1em;
    left: 13.2em;
    top: 12.9em;
    width: .5em
}

.pl9 .s7 {
    height: .2em;
    left: 13.2em;
    top: 12.7em;
    width: .6em
}

.pl9 .s8 {
    height: .1em;
    left: 13.2em;
    top: 12.6em;
    width: .7em
}

.pl9 .s9 {
    height: .1em;
    left: 13.2em;
    top: 12.5em;
    width: .8em
}

.pl9 .s10 {
    height: .2em;
    left: 14.5em;
    top: 11.7em;
    width: .1em
}

.pl9 .s11 {
    height: .3em;
    left: 14.4em;
    top: 11.7em;
    width: .1em
}

.pl9 .s12 {
    height: .7em;
    left: 14.3em;
    top: 11.4em;
    width: .1em
}

.pl9 .s13 {
    height: .8em;
    left: 14em;
    top: 11.5em;
    width: .3em
}

.pl9 .s14 {
    height: .8em;
    left: 13.9em;
    top: 11.6em;
    width: .3em
}

.pl9 .s15 {
    height: .8em;
    left: 11.1em;
    top: 11.7em;
    width: 3em
}

.pl9 .s16 {
    height: .1em;
    left: 11.1em;
    top: 11.5em;
    width: .1em
}

.pl9 .s17 {
    height: 1.3em;
    left: 11.2em;
    top: 11.4em;
    width: .1em
}

.pl9 .s18 {
    height: .1em;
    left: 11.2em;
    top: 12.9em;
    width: .1em
}

.pl9 .s19 {
    height: 1.7em;
    left: 11.3em;
    top: 11.4em;
    width: .1em
}

.pl9 .s20 {
    height: 2.5em;
    left: 11.4em;
    top: 11.3em;
    width: .1em
}

.pl9 .s21 {
    height: 2.6em;
    left: 11.5em;
    top: 11.2em;
    width: .3em
}

.pl9 .s22 {
    height: .3em;
    left: 11.7em;
    top: 11.1em;
    width: 1.3em
}

.pl9 .s23 {
    height: .3em;
    left: 12.9em;
    top: 11em;
    width: .2em
}

.pl9 .s24 {
    height: 2.9em;
    left: 11.8em;
    top: 10.9em;
    width: .1em
}

.pl9 .s25 {
    height: 2.8em;
    left: 12.6em;
    top: 11.6em;
    width: .6em
}

.pl9 .s26 {
    height: 2.8em;
    left: 12.4em;
    top: 11.5em;
    width: .6em
}

.pl9 .s27 {
    height: 3.2em;
    left: 12.3em;
    top: 11em;
    width: .6em
}

.pl9 .s28 {
    height: 3.1em;
    left: 12.2em;
    top: 10.9em;
    width: .6em
}

.pl9 .s29 {
    height: 3.1em;
    left: 11.9em;
    top: 10.8em;
    width: .8em
}

.pl9 .s30 {
    height: .1em;
    left: 12em;
    top: 10.7em;
    width: .6em
}

.pl9 .s31 {
    height: .1em;
    left: 12.1em;
    top: 10.6em;
    width: .5em
}

.pl9 .s32 {
    height: .1em;
    left: 12.3em;
    top: 10.5em;
    width: .2em
}

.pl10 .s1 {
    height: .2em;
    left: 13.5em;
    top: 1.6em;
    width: .4em
}

.pl10 .s2 {
    height: .1em;
    left: 13.5em;
    top: 1.8em;
    width: .6em
}

.pl10 .s3 {
    height: .1em;
    left: 13em;
    top: 2.1em;
    width: .1em
}

.pl10 .s4 {
    height: .3em;
    left: 13.1em;
    top: 2em;
    width: .1em
}

.pl10 .s5 {
    height: .6em;
    left: 13.2em;
    top: 1.9em;
    width: 1.1em
}

.pl10 .s6 {
    height: .7em;
    left: 13.3em;
    top: 2em;
    width: 1.1em
}

.pl10 .s7 {
    height: .7em;
    left: 13.4em;
    top: 2.1em;
    width: 1.1em
}

.pl10 .s8 {
    height: .8em;
    left: 13.5em;
    top: 2.2em;
    width: 1.1em
}

.pl10 .s9 {
    height: .4em;
    left: 14.6em;
    top: 3em;
    width: .1em
}

.pl10 .s10 {
    height: .1em;
    left: 12.8em;
    top: 3.5em;
    width: .2em
}

.pl10 .s11 {
    height: .1em;
    left: 12.7em;
    top: 3.6em;
    width: .3em
}

.pl10 .s12 {
    height: .1em;
    left: 12.5em;
    top: 3.7em;
    width: .5em
}

.pl10 .s13 {
    height: .1em;
    left: 12.4em;
    top: 3.8em;
    width: .4em
}

.pl10 .s14 {
    height: .2em;
    left: 11.7em;
    top: 3.9em;
    width: .1em
}

.pl10 .s15 {
    height: .4em;
    left: 11.8em;
    top: 3.9em;
    width: .2em
}

.pl10 .s16 {
    height: .3em;
    left: 11.8em;
    top: 4.4em;
    width: .1em
}

.pl10 .s17 {
    height: .9em;
    left: 11.9em;
    top: 4em;
    width: .2em
}

.pl10 .s18 {
    height: 1em;
    left: 12.1em;
    top: 4em;
    width: .1em
}

.pl10 .s19 {
    height: 1.3em;
    left: 12.2em;
    top: 3.9em;
    width: .6em
}

.pl10 .s20 {
    height: .8em;
    left: 15.2em;
    top: 5.1em;
    width: .1em
}

.pl10 .s21 {
    height: 1.4em;
    left: 15em;
    top: 4.7em;
    width: .2em
}

.pl10 .s22 {
    height: 1em;
    left: 12.3em;
    top: 4.4em;
    width: 2.8em
}

.pl10 .s23 {
    height: .1em;
    left: 12.4em;
    top: 5.4em;
    width: .2em
}

.pl10 .s24 {
    height: 2.1em;
    left: 14.8em;
    top: 4.1em;
    width: .2em
}

.pl10 .s25 {
    height: 1.9em;
    left: 12.8em;
    top: 3.8em;
    width: 2.1em
}

.pl10 .s26 {
    height: .2em;
    left: 12.7em;
    top: 5.5em;
    width: .1em
}

.pl10 .s27 {
    height: 3.3em;
    left: 13.4em;
    top: 3em;
    width: 1.2em
}

.pl10 .s28 {
    height: 2.8em;
    left: 13em;
    top: 3.4em;
    width: 1.8em
}

.pl10 .s29 {
    height: 3.2em;
    left: 13.3em;
    top: 3.2em;
    width: 1.2em
}

.pl10 .s30 {
    height: 3.2em;
    left: 13.1em;
    top: 3.3em;
    width: 1.3em
}

.pl10 .s31 {
    height: .2em;
    left: 13.2em;
    top: 6.5em;
    width: 1.1em
}

.pl10 .s32 {
    height: .1em;
    left: 13.8em;
    top: 6.7em;
    width: .4em
}

.pl10 .s33 {
    height: .1em;
    left: 13.9em;
    top: 6.8em;
    width: .3em
}

.pl11 .s1 {
    height: .3em;
    left: 7.4em;
    top: .9em;
    width: .2em
}

.pl11 .s2 {
    height: .2em;
    left: 7.3em;
    top: .8em;
    width: .2em
}

.pl11 .s3 {
    height: .2em;
    left: 7.2em;
    top: .7em;
    width: .2em
}

.pl11 .s4 {
    height: .4em;
    left: 7em;
    top: .6em;
    width: .2em
}

.pl11 .s5 {
    height: .3em;
    left: 4.4em;
    top: 1.1em;
    width: .1em
}

.pl11 .s6 {
    height: .4em;
    left: 4.5em;
    top: 1.1em;
    width: .1em
}

.pl11 .s7 {
    height: .3em;
    left: 4.4em;
    top: 2em;
    width: .1em
}

.pl11 .s8 {
    height: .7em;
    left: 4.5em;
    top: 2em;
    width: .1em
}

.pl11 .s9 {
    height: .1em;
    left: 7.2em;
    top: 1.5em;
    width: .1em
}

.pl11 .s10 {
    height: 1.8em;
    left: 4.6em;
    top: 1em;
    width: .1em
}

.pl11 .s11 {
    height: 1.9em;
    left: 4.7em;
    top: 1em;
    width: 2.5em
}

.pl11 .s12 {
    height: 2.7em;
    left: 5.8em;
    top: .5em;
    width: 1.2em
}

.pl11 .s13 {
    height: .3em;
    left: 4.6em;
    top: 3.2em;
    width: .1em
}

.pl11 .s14 {
    height: .1em;
    left: 4.6em;
    top: 3em;
    width: .1em
}

.pl11 .s15 {
    height: .2em;
    left: 8.6em;
    top: 1.5em;
    width: .2em
}

.pl11 .s16 {
    height: .2em;
    left: 8.4em;
    top: 1.5em;
    width: .2em
}

.pl11 .s17 {
    height: .3em;
    left: 8.2em;
    top: 1.6em;
    width: .2em
}

.pl11 .s18 {
    height: .2em;
    left: 8.2em;
    top: 2em;
    width: .1em
}

.pl11 .s19 {
    height: .1em;
    left: 8.6em;
    top: 2.8em;
    width: .1em
}

.pl11 .s20 {
    height: .1em;
    left: 8.5em;
    top: 3.1em;
    width: .1em
}

.pl11 .s21 {
    height: .3em;
    left: 8.4em;
    top: 2.8em;
    width: .2em
}

.pl11 .s22 {
    height: .5em;
    left: 8.3em;
    top: 2.6em;
    width: .1em
}

.pl11 .s23 {
    height: .7em;
    left: 8.2em;
    top: 2.6em;
    width: .1em
}

.pl11 .s24 {
    height: .1em;
    left: 8.1em;
    top: 3.3em;
    width: .1em
}

.pl11 .s25 {
    height: .1em;
    left: 7.6em;
    top: 3.6em;
    width: .4em
}

.pl11 .s26 {
    height: .3em;
    left: 7.5em;
    top: 3.3em;
    width: .6em
}

.pl11 .s27 {
    height: .1em;
    left: 7.4em;
    top: 3.5em;
    width: .1em
}

.pl11 .s28 {
    height: .1em;
    left: 7.1em;
    top: 3.4em;
    width: .2em
}

.pl11 .s29 {
    height: 1.8em;
    left: 6.7em;
    top: 1.6em;
    width: .8em
}

.pl11 .s30 {
    height: 1.6em;
    left: 6.6em;
    top: 1.7em;
    width: 1.6em
}

.pl11 .s31 {
    height: 2.7em;
    left: 5.3em;
    top: .6em;
    width: 1.1em
}

.pl11 .s32 {
    height: 2.7em;
    left: 5.1em;
    top: .7em;
    width: 1em
}

.pl11 .s33 {
    height: 2.8em;
    left: 4.9em;
    top: .8em;
    width: 1em
}

.pl11 .s34 {
    height: 2.8em;
    left: 4.8em;
    top: .9em;
    width: .7em
}

.pl11 .s35 {
    height: .8em;
    left: 4.7em;
    top: 3em;
    width: .7em
}

.pl11 .s36 {
    height: .1em;
    left: 4.8em;
    top: 3.8em;
    width: .1em
}

.pl11 .s37 {
    height: .1em;
    left: 5.3em;
    top: 3.8em;
    width: .1em
}

.pl11 .s38 {
    height: .1em;
    left: 5.6em;
    top: 3.6em;
    width: .3em
}

.pl12 .s1 {
    height: .4em;
    left: 6.4em;
    top: 12em;
    width: .2em
}

.pl12 .s2 {
    height: .3em;
    left: 7.1em;
    top: 12.6em;
    width: .1em
}

.pl12 .s3 {
    height: .1em;
    left: 6.7em;
    top: 12.5em;
    width: .5em
}

.pl12 .s4 {
    height: .6em;
    left: 6.6em;
    top: 11.9em;
    width: .6em
}

.pl12 .s5 {
    height: .1em;
    left: 6.8em;
    top: 11.8em;
    width: 1.6em
}

.pl12 .s6 {
    height: .1em;
    left: 9.2em;
    top: 11.2em;
    width: .1em
}

.pl12 .s7 {
    height: .2em;
    left: 9.1em;
    top: 11.1em;
    width: .1em
}

.pl12 .s8 {
    height: .4em;
    left: 8.9em;
    top: 10.9em;
    width: .2em
}

.pl12 .s9 {
    height: .2em;
    left: 7.1em;
    top: 10.7em;
    width: 2em
}

.pl12 .s10 {
    height: .1em;
    left: 8.9em;
    top: 10.4em;
    width: .2em
}

.pl12 .s11 {
    height: 1em;
    left: 8.8em;
    top: 10.4em;
    width: .1em
}

.pl12 .s12 {
    height: .1em;
    left: 7.1em;
    top: 10.5em;
    width: 1.9em
}

.pl12 .s13 {
    height: 1.1em;
    left: 8.6em;
    top: 10.3em;
    width: .2em
}

.pl12 .s14 {
    height: 1.2em;
    left: 8.5em;
    top: 10.3em;
    width: .1em
}

.pl12 .s15 {
    height: 1.4em;
    left: 8.3em;
    top: 10.2em;
    width: .2em
}

.pl12 .s16 {
    height: .1em;
    left: 8.3em;
    top: 10em;
    width: .1em
}

.pl12 .s17 {
    height: .1em;
    left: 7.2em;
    top: 10.1em;
    width: 1.3em
}

.pl12 .s18 {
    height: .1em;
    left: 8em;
    top: 10em;
    width: .1em
}

.pl12 .s19 {
    height: 2em;
    left: 8.2em;
    top: 10em;
    width: .1em
}

.pl12 .s20 {
    height: 2em;
    left: 8em;
    top: 10.1em;
    width: .2em
}

.pl12 .s21 {
    height: .4em;
    left: 6.9em;
    top: 11.3em;
    width: 1.5em
}

.pl12 .s22 {
    height: 1.1em;
    left: 7em;
    top: 11.1em;
    width: 1.1em
}

.pl12 .s23 {
    height: 2.7em;
    left: 7.2em;
    top: 10.4em;
    width: .1em
}

.pl12 .s24 {
    height: .3em;
    left: 7.7em;
    top: 13.5em;
    width: .5em
}

.pl12 .s25 {
    height: .3em;
    left: 8.4em;
    top: 13.1em;
    width: .1em
}

.pl12 .s26 {
    height: .1em;
    left: 8.4em;
    top: 12.9em;
    width: .1em
}

.pl12 .s27 {
    height: .6em;
    left: 8.2em;
    top: 12.9em;
    width: .2em
}

.pl12 .s28 {
    height: .8em;
    left: 7.5em;
    top: 12.7em;
    width: .7em
}

.pl12 .s29 {
    height: .7em;
    left: 7.4em;
    top: 12.5em;
    width: .7em
}

.pl12 .s30 {
    height: 3.2em;
    left: 7.3em;
    top: 9.9em;
    width: .7em
}

.pl12 .s31 {
    height: .1em;
    left: 7.7em;
    top: 9.8em;
    width: .1em
}

.pl13 .s1 {
    height: .1em;
    left: 8.9em;
    top: 10.6em;
    width: .1em
}

.pl13 .s2 {
    height: .2em;
    left: 9em;
    top: 10.5em;
    width: .1em
}

.pl13 .s3 {
    height: .2em;
    left: 9em;
    top: 10.2em;
    width: .1em
}

.pl13 .s4 {
    height: .1em;
    left: 9.1em;
    top: 9.9em;
    width: .1em
}

.pl13 .s5 {
    height: 1.1em;
    left: 9.1em;
    top: 10em;
    width: .2em
}

.pl13 .s6 {
    height: 1.1em;
    left: 9.2em;
    top: 10.1em;
    width: .2em
}

.pl13 .s7 {
    height: .3em;
    left: 9.3em;
    top: 9.6em;
    width: .1em
}

.pl13 .s8 {
    height: 1.6em;
    left: 9.4em;
    top: 9.6em;
    width: .2em
}

.pl13 .s9 {
    height: 1.7em;
    left: 9.6em;
    top: 9.5em;
    width: .1em
}

.pl13 .s10 {
    height: .4em;
    left: 12em;
    top: 10.3em;
    width: .1em
}

.pl13 .s11 {
    height: .9em;
    left: 11.9em;
    top: 9.9em;
    width: .1em
}

.pl13 .s12 {
    height: 1em;
    left: 11.8em;
    top: 9.9em;
    width: .1em
}

.pl13 .s13 {
    height: 1.2em;
    left: 11.6em;
    top: 9.9em;
    width: .2em
}

.pl13 .s14 {
    height: 1.2em;
    left: 11.5em;
    top: 10em;
    width: .2em
}

.pl13 .s15 {
    height: 1.3em;
    left: 11.4em;
    top: 10em;
    width: .1em
}

.pl13 .s16 {
    height: 1.5em;
    left: 11.3em;
    top: 9.9em;
    width: .1em
}

.pl13 .s17 {
    height: 1.6em;
    left: 11em;
    top: 9.8em;
    width: .3em
}

.pl13 .s18 {
    height: .1em;
    left: 11em;
    top: 11.4em;
    width: .2em
}

.pl13 .s19 {
    height: .1em;
    left: 11em;
    top: 11.5em;
    width: .1em
}

.pl13 .s20 {
    height: 1.9em;
    left: 10.8em;
    top: 9.7em;
    width: .2em
}

.pl13 .s21 {
    height: .1em;
    left: 10.9em;
    top: 9.6em;
    width: .1em
}

.pl13 .s22 {
    height: 2em;
    left: 10.6em;
    top: 9.6em;
    width: .2em
}

.pl13 .s23 {
    height: 2em;
    left: 10.5em;
    top: 9.7em;
    width: .2em
}

.pl13 .s24 {
    height: 2.1em;
    left: 10.3em;
    top: 9.7em;
    width: .2em
}

.pl13 .s25 {
    height: .1em;
    left: 9.9em;
    top: 11.8em;
    width: .5em
}

.pl13 .s26 {
    height: 2.3em;
    left: 9.8em;
    top: 9.5em;
    width: .5em
}

.pl13 .s27 {
    height: 2em;
    left: 9.7em;
    top: 9.4em;
    width: .4em
}

.pl13 .s28 {
    height: .2em;
    left: 9.9em;
    top: 9.2em;
    width: .2em
}

.pl14 .s1 {
    height: .4em;
    left: 8.2em;
    top: 2.2em;
    width: .1em
}

.pl14 .s2 {
    height: .1em;
    left: 8.2em;
    top: 1.9em;
    width: .1em
}

.pl14 .s3 {
    height: .7em;
    left: 8.3em;
    top: 1.9em;
    width: .1em
}

.pl14 .s4 {
    height: 1.1em;
    left: 8.4em;
    top: 1.7em;
    width: .2em
}

.pl14 .s5 {
    height: 1.1em;
    left: 8.6em;
    top: 1.7em;
    width: .1em
}

.pl14 .s6 {
    height: .1em;
    left: 8.9em;
    top: 1.5em;
    width: .9em
}

.pl14 .s7 {
    height: .1em;
    left: 8.8em;
    top: 1.6em;
    width: 1.7em
}

.pl14 .s8 {
    height: .2em;
    left: 13.2em;
    top: 1.7em;
    width: .3em
}

.pl14 .s9 {
    height: .3em;
    left: 13.1em;
    top: 1.7em;
    width: .1em
}

.pl14 .s10 {
    height: .4em;
    left: 13em;
    top: 1.7em;
    width: .1em
}

.pl14 .s11 {
    height: .2em;
    left: 13.4em;
    top: 2.8em;
    width: .1em
}

.pl14 .s12 {
    height: .5em;
    left: 13.3em;
    top: 2.7em;
    width: .1em
}

.pl14 .s13 {
    height: .8em;
    left: 13.2em;
    top: 2.5em;
    width: .1em
}

.pl14 .s14 {
    height: 1em;
    left: 13.1em;
    top: 2.3em;
    width: .1em
}

.pl14 .s15 {
    height: 1.2em;
    left: 13em;
    top: 2.2em;
    width: .1em
}

.pl14 .s16 {
    height: 1.8em;
    left: 12.8em;
    top: 1.7em;
    width: .2em
}

.pl14 .s17 {
    height: 1.9em;
    left: 12.7em;
    top: 1.7em;
    width: .1em
}

.pl14 .s18 {
    height: 2em;
    left: 8.7em;
    top: 1.7em;
    width: 4em
}

.pl14 .s19 {
    height: .1em;
    left: 9.2em;
    top: 4.7em;
    width: .2em
}

.pl14 .s20 {
    height: .1em;
    left: 9.5em;
    top: 4.7em;
    width: .3em
}

.pl14 .s21 {
    height: .1em;
    left: 9.2em;
    top: 4.6em;
    width: .8em
}

.pl14 .s22 {
    height: .1em;
    left: 9em;
    top: 4.7em;
    width: .1em
}

.pl14 .s23 {
    height: .1em;
    left: 8.9em;
    top: 4.6em;
    width: .2em
}

.pl14 .s24 {
    height: .1em;
    left: 8.9em;
    top: 4.5em;
    width: 1.3em
}

.pl14 .s25 {
    height: .1em;
    left: 8.9em;
    top: 4.4em;
    width: 1.4em
}

.pl14 .s26 {
    height: .1em;
    left: 8.9em;
    top: 4.3em;
    width: 1.8em
}

.pl14 .s27 {
    height: .1em;
    left: 10.8em;
    top: 4.2em;
    width: .2em
}

.pl14 .s28 {
    height: .1em;
    left: 8.5em;
    top: 4.2em;
    width: 2.2em
}

.pl14 .s29 {
    height: .1em;
    left: 8.4em;
    top: 4.1em;
    width: 2.7em
}

.pl14 .s30 {
    height: 1.1em;
    left: 8.6em;
    top: 2.9em;
    width: 3em
}

.pl14 .s31 {
    height: .1em;
    left: 12em;
    top: 3.9em;
    width: .2em
}

.pl14 .s32 {
    height: .6em;
    left: 8.3em;
    top: 3.2em;
    width: 4.2em
}

.pl14 .s33 {
    height: .1em;
    left: 8.3em;
    top: 3.1em;
    width: .2em
}

.pl14 .s34 {
    height: .1em;
    left: 8.2em;
    top: 4.1em;
    width: .1em
}

.pl14 .s35 {
    height: .8em;
    left: 8.2em;
    top: 3.3em;
    width: 3.3em
}

.pl14 .s36 {
    height: .5em;
    left: 8.1em;
    top: 3.4em;
    width: 4.3em
}

.pl14 .s37 {
    height: .1em;
    left: 8em;
    top: 3.6em;
    width: .1em
}

.pl15 .s1 {
    height: .1em;
    left: 7.9em;
    top: 6.9em;
    width: .1em
}

.pl15 .s2 {
    height: .3em;
    left: 7.8em;
    top: 6.8em;
    width: .1em
}

.pl15 .s3 {
    height: .4em;
    left: 7.6em;
    top: 6.7em;
    width: .2em
}

.pl15 .s4 {
    height: .1em;
    left: 7.5em;
    top: 7.4em;
    width: .1em
}

.pl15 .s5 {
    height: .6em;
    left: 7.4em;
    top: 6.8em;
    width: .3em
}

.pl15 .s6 {
    height: 1.4em;
    left: 7.1em;
    top: 6.6em;
    width: .3em
}

.pl15 .s7 {
    height: 1.6em;
    left: 7em;
    top: 6.4em;
    width: .1em
}

.pl15 .s8 {
    height: 1.5em;
    left: 6.9em;
    top: 6.7em;
    width: .1em
}

.pl15 .s9 {
    height: .1em;
    left: 6.1em;
    top: 9.7em;
    width: .2em
}

.pl15 .s10 {
    height: .1em;
    left: 6.1em;
    top: 9.6em;
    width: .3em
}

.pl15 .s11 {
    height: .2em;
    left: 6em;
    top: 9.4em;
    width: .6em
}

.pl15 .s12 {
    height: .1em;
    left: 5.9em;
    top: 9.3em;
    width: .6em
}

.pl15 .s13 {
    height: .1em;
    left: 6em;
    top: 9.2em;
    width: .4em
}

.pl15 .s14 {
    height: .1em;
    left: 6.5em;
    top: 9em;
    width: .1em
}

.pl15 .s15 {
    height: .2em;
    left: 5.6em;
    top: 8.8em;
    width: 1.2em
}

.pl15 .s16 {
    height: .1em;
    left: 6.8em;
    top: 6.3em;
    width: .1em
}

.pl15 .s17 {
    height: 2.5em;
    left: 5.7em;
    top: 6.4em;
    width: 1.2em
}

.pl15 .s18 {
    height: 2.9em;
    left: 6.1em;
    top: 6.3em;
    width: .4em
}

.pl15 .s19 {
    height: 2.4em;
    left: 5.6em;
    top: 6.2em;
    width: .7em
}

.pl15 .s20 {
    height: 2.4em;
    left: 5.5em;
    top: 6.1em;
    width: .7em
}

.pl15 .s21 {
    height: 3.2em;
    left: 5.9em;
    top: 6em;
    width: .2em
}

.pl15 .s22 {
    height: .1em;
    left: 4.7em;
    top: 8.6em;
    width: .3em
}

.pl15 .s23 {
    height: .1em;
    left: 4.5em;
    top: 8.5em;
    width: .6em
}

.pl15 .s24 {
    height: .1em;
    left: 5.6em;
    top: 5.9em;
    width: .2em
}

.pl15 .s25 {
    height: 2.7em;
    left: 5.4em;
    top: 5.8em;
    width: .1em
}

.pl15 .s26 {
    height: .4em;
    left: 5.4em;
    top: 5.3em;
    width: .2em
}

.pl15 .s27 {
    height: 3.3em;
    left: 4.3em;
    top: 5.2em;
    width: 1.1em
}

.pl15 .s28 {
    height: .3em;
    left: 3em;
    top: 5.9em;
    width: .2em
}

.pl15 .s29 {
    height: .4em;
    left: 3.1em;
    top: 6em;
    width: 2.6em
}

.pl15 .s30 {
    height: .2em;
    left: 3.1em;
    top: 6.5em;
    width: 4.2em
}

.pl15 .s31 {
    height: .2em;
    left: 3.1em;
    top: 7em;
    width: .1em
}

.pl15 .s32 {
    height: .1em;
    left: 3.1em;
    top: 7.3em;
    width: .1em
}

.pl15 .s33 {
    height: 1.8em;
    left: 3.2em;
    top: 5.6em;
    width: .1em
}

.pl15 .s34 {
    height: 2.5em;
    left: 3.3em;
    top: 5.1em;
    width: .2em
}

.pl15 .s35 {
    height: 2.8em;
    left: 3.5em;
    top: 5.1em;
    width: .1em
}

.pl15 .s36 {
    height: 2.9em;
    left: 3.6em;
    top: 5em;
    width: .2em
}

.pl15 .s37 {
    height: 3em;
    left: 3.8em;
    top: 4.9em;
    width: .1em
}

.pl15 .s38 {
    height: 3.3em;
    left: 3.9em;
    top: 4.8em;
    width: .1em
}

.pl15 .s39 {
    height: 3.4em;
    left: 4em;
    top: 4.7em;
    width: .1em
}

.pl15 .s40 {
    height: .1em;
    left: 5.3em;
    top: 4.8em;
    width: .1em
}

.pl15 .s41 {
    height: 3.6em;
    left: 4.1em;
    top: 4.6em;
    width: 1.2em
}

.pl15 .s42 {
    height: .2em;
    left: 5.4em;
    top: 4.4em;
    width: .1em
}

.pl15 .s43 {
    height: .3em;
    left: 4.3em;
    top: 4.3em;
    width: 1.1em
}

.pl15 .s44 {
    height: .1em;
    left: 4.1em;
    top: 4.2em;
    width: 1.1em
}

.pl15 .s45 {
    height: .2em;
    left: 4em;
    top: 4em;
    width: 1.2em
}

.pl15 .s46 {
    height: .1em;
    left: 4em;
    top: 3.9em;
    width: 1.4em
}

.pl15 .s47 {
    height: .3em;
    left: 4.9em;
    top: 3.8em;
    width: .4em
}

.pl15 .s48 {
    height: .1em;
    left: 4.4em;
    top: 3.8em;
    width: .4em
}

.pl15 .s49 {
    height: .2em;
    left: 4.4em;
    top: 3.6em;
    width: .3em
}

.pl15 .s50 {
    height: .1em;
    left: 4.5em;
    top: 3.5em;
    width: .2em
}

.pl16 .s1 {
    height: .3em;
    left: .6em;
    top: 2.8em;
    width: .1em
}

.pl16 .s2 {
    height: 1.1em;
    left: .7em;
    top: 2.8em;
    width: .1em
}

.pl16 .s3 {
    height: 1.4em;
    left: .8em;
    top: 2.8em;
    width: .1em
}

.pl16 .s4 {
    height: .4em;
    left: .5em;
    top: 5.2em;
    width: .1em
}

.pl16 .s5 {
    height: .6em;
    left: .6em;
    top: 5.1em;
    width: .1em
}

.pl16 .s6 {
    height: .8em;
    left: .7em;
    top: 5em;
    width: .1em
}

.pl16 .s7 {
    height: .2em;
    left: .8em;
    top: 5.7em;
    width: .1em
}

.pl16 .s8 {
    height: .3em;
    left: .9em;
    top: 5.7em;
    width: .1em
}

.pl16 .s9 {
    height: .4em;
    left: 1em;
    top: 5.7em;
    width: .2em
}

.pl16 .s10 {
    height: .3em;
    left: 1.2em;
    top: 5.7em;
    width: .3em
}

.pl16 .s11 {
    height: 1.1em;
    left: .8em;
    top: 4.6em;
    width: .8em
}

.pl16 .s12 {
    height: 2.9em;
    left: .9em;
    top: 2.7em;
    width: .8em
}

.pl16 .s13 {
    height: .1em;
    left: 1.9em;
    top: 5.5em;
    width: .1em
}

.pl16 .s14 {
    height: 2.9em;
    left: 1.1em;
    top: 2.6em;
    width: 1.1em
}

.pl16 .s15 {
    height: 2.9em;
    left: 1.3em;
    top: 2.5em;
    width: 1em
}

.pl16 .s16 {
    height: 2.9em;
    left: 1.6em;
    top: 2.4em;
    width: .8em
}

.pl16 .s17 {
    height: 2.9em;
    left: 1.8em;
    top: 2.3em;
    width: .8em
}

.pl16 .s18 {
    height: 2.9em;
    left: 2.1em;
    top: 2.2em;
    width: .9em
}

.pl16 .s19 {
    height: .1em;
    left: 2.7em;
    top: 5.1em;
    width: .3em
}

.pl16 .s20 {
    height: 2.8em;
    left: 2.5em;
    top: 2.1em;
    width: .6em
}

.pl16 .s21 {
    height: 2.8em;
    left: 2.9em;
    top: 2em;
    width: .3em
}

.pl16 .s22 {
    height: .3em;
    left: 4.1em;
    top: 4.3em;
    width: .2em
}

.pl16 .s23 {
    height: .5em;
    left: 4em;
    top: 4.2em;
    width: .1em
}

.pl16 .s24 {
    height: .1em;
    left: 4.6em;
    top: 3.1em;
    width: .1em
}

.pl16 .s25 {
    height: .1em;
    left: 4.7em;
    top: 2.9em;
    width: .1em
}

.pl16 .s26 {
    height: .2em;
    left: 4.6em;
    top: 2.8em;
    width: .1em
}

.pl16 .s27 {
    height: .8em;
    left: 4.5em;
    top: 2.7em;
    width: .1em
}

.pl16 .s28 {
    height: 1.3em;
    left: 4.4em;
    top: 2.3em;
    width: .1em
}

.pl16 .s29 {
    height: .1em;
    left: 3.5em;
    top: 1.8em;
    width: .1em
}

.pl16 .s30 {
    height: 3em;
    left: 3.2em;
    top: 1.9em;
    width: .4em
}

.pl16 .s31 {
    height: .2em;
    left: 3.3em;
    top: 4.9em;
    width: .3em
}

.pl16 .s32 {
    height: 3.3em;
    left: 3.6em;
    top: 1.7em;
    width: .2em
}

.pl16 .s33 {
    height: 3.3em;
    left: 3.7em;
    top: 1.6em;
    width: .2em
}

.pl16 .s34 {
    height: .5em;
    left: 4.5em;
    top: 1.5em;
    width: .1em
}

.pl16 .s35 {
    height: .6em;
    left: 3.8em;
    top: 1.4em;
    width: .7em
}

.pl16 .s36 {
    height: 3.5em;
    left: 3.9em;
    top: 1.3em;
    width: .1em
}

.pl16 .s37 {
    height: 2.7em;
    left: 4em;
    top: 1.2em;
    width: .2em
}

.pl16 .s38 {
    height: 2.8em;
    left: 4.2em;
    top: 1.1em;
    width: .2em
}

/* size: 210 */
.cssmap-210 {
    width: 210px
}

.cssmap-210 .poland, .cssmap-210 .poland .bg, .cssmap-210 .poland-cities {
    background: transparent url('default/pl-210.png') no-repeat 0 0
}

/*.cssmap-210 .poland.cssmap-blue, .cssmap-210 .poland.cssmap-blue .bg, .cssmap-210 .poland-cities.cssmap-blue {*/
/*    background-image: url('blue/pl-210.png')*/
/*}*/

/*.cssmap-210 .poland.cssmap-dark, .cssmap-210 .poland.cssmap-dark .bg, .cssmap-210 .poland-cities.cssmap-dark {*/
/*    background-image: url('dark/pl-210.png')*/
/*}*/

/*.cssmap-210 .poland.cssmap-vintage, .cssmap-210 .poland.cssmap-vintage .bg, .cssmap-210 .poland-cities.cssmap-vintage {*/
/*    background-image: url('vintage/pl-210.png')*/
/*}*/

/*.cssmap-210 .poland.cssmap-custom, .cssmap-210 .poland.cssmap-custom .bg, .cssmap-210 .poland-cities.cssmap-custom {*/
/*    background-image: url('custom/pl-210.png')*/
/*}*/

.cssmap-210 .poland, .cssmap-210 .poland-cities {
    height: 200px;
    width: 210px
}

.cssmap-210 .poland-cities {
    background-position: -220px 0
}

.cssmap-210 .poland .m {
    margin: 9999px 0 0 0
}

.cssmap-210 .poland .m span {
    font-size: 13px !important;
    padding: 0 1px 1px 0
}

.cssmap-210 .poland .bg {
    padding: 5px
}

.cssmap-210 .pl1.focus .bg, .cssmap-210 .pl1.active-region .bg {
    height: 53px;
    left: 15px;
    top: 101px;
    width: 58px
}

.cssmap-210 .pl1.focus .bg {
    background-position: -174px -327px
}

.cssmap-210 .pl1.active-region .bg {
    background-position: -474px -327px
}

.cssmap-210 .pl2.focus .bg, .cssmap-210 .pl2.active-region .bg {
    height: 48px;
    left: 62px;
    top: 37px;
    width: 50px
}

.cssmap-210 .pl2.focus .bg {
    background-position: -236px -242px
}

.cssmap-210 .pl2.active-region .bg {
    background-position: -536px -242px
}

.cssmap-210 .pl3.focus .bg, .cssmap-210 .pl3.active-region .bg {
    height: 64px;
    left: 148px;
    top: 85px;
    width: 49px
}

.cssmap-210 .pl3.focus .bg {
    background-position: -6px -389px
}

.cssmap-210 .pl3.active-region .bg {
    background-position: -306px -389px
}

.cssmap-210 .pl4.focus .bg, .cssmap-210 .pl4.active-region .bg {
    height: 56px;
    left: 9px;
    top: 58px;
    width: 37px
}

.cssmap-210 .pl4.focus .bg {
    background-position: -247px -305px
}

.cssmap-210 .pl4.active-region .bg {
    background-position: -547px -305px
}

.cssmap-210 .pl5.focus .bg, .cssmap-210 .pl5.active-region .bg {
    height: 49px;
    left: 79px;
    top: 82px;
    width: 51px
}

.cssmap-210 .pl5.focus .bg {
    background-position: -146px -467px
}

.cssmap-210 .pl5.active-region .bg {
    background-position: -446px -467px
}

.cssmap-210 .pl6.focus .bg, .cssmap-210 .pl6.active-region .bg {
    height: 41px;
    left: 99px;
    top: 141px;
    width: 46px
}

.cssmap-210 .pl6.focus .bg {
    background-position: -87px -336px
}

.cssmap-210 .pl6.active-region .bg {
    background-position: -387px -336px
}

.cssmap-210 .pl7.focus .bg, .cssmap-210 .pl7.active-region .bg {
    height: 79px;
    left: 102px;
    top: 47px;
    width: 76px
}

.cssmap-210 .pl7.focus .bg {
    background-position: -84px -242px
}

.cssmap-210 .pl7.active-region .bg {
    background-position: -384px -242px
}

.cssmap-210 .pl8.focus .bg, .cssmap-210 .pl8.active-region .bg {
    height: 39px;
    left: 56px;
    top: 120px;
    width: 35px
}

.cssmap-210 .pl8.focus .bg {
    background-position: -5px -469px
}

.cssmap-210 .pl8.active-region .bg {
    background-position: -305px -469px
}

.cssmap-210 .pl9.focus .bg, .cssmap-210 .pl9.active-region .bg {
    height: 56px;
    left: 138px;
    top: 131px;
    width: 47px
}

.cssmap-210 .pl9.focus .bg {
    background-position: -144px -395px
}

.cssmap-210 .pl9.active-region .bg {
    background-position: -444px -395px
}

.cssmap-210 .pl10.focus .bg, .cssmap-210 .pl10.active-region .bg {
    height: 70px;
    left: 147px;
    top: 16px;
    width: 46px
}

.cssmap-210 .pl10.focus .bg {
    background-position: -175px -242px
}

.cssmap-210 .pl10.active-region .bg {
    background-position: -475px -242px
}

.cssmap-210 .pl11.focus .bg, .cssmap-210 .pl11.active-region .bg {
    height: 45px;
    left: 52px;
    top: 2px;
    width: 58px
}

.cssmap-210 .pl11.focus .bg {
    background-position: -71px -473px
}

.cssmap-210 .pl11.active-region .bg {
    background-position: -371px -473px
}

.cssmap-210 .pl12.focus .bg, .cssmap-210 .pl12.active-region .bg {
    height: 52px;
    left: 78px;
    top: 123px;
    width: 38px
}

.cssmap-210 .pl12.focus .bg {
    background-position: -225px -459px
}

.cssmap-210 .pl12.active-region .bg {
    background-position: -525px -459px
}

.cssmap-210 .pl13.focus .bg, .cssmap-210 .pl13.active-region .bg {
    height: 36px;
    left: 110px;
    top: 115px;
    width: 43px
}

.cssmap-210 .pl13.focus .bg {
    background-position: -5px -338px
}

.cssmap-210 .pl13.active-region .bg {
    background-position: -305px -338px
}

.cssmap-210 .pl14.focus .bg, .cssmap-210 .pl14.active-region .bg {
    height: 44px;
    left: 99px;
    top: 15px;
    width: 72px
}

.cssmap-210 .pl14.focus .bg {
    background-position: -207px -396px
}

.cssmap-210 .pl14.active-region .bg {
    background-position: -507px -396px
}

.cssmap-210 .pl15.focus .bg, .cssmap-210 .pl15.active-region .bg {
    height: 82px;
    left: 34px;
    top: 41px;
    width: 65px
}

.cssmap-210 .pl15.focus .bg {
    background-position: -5px -242px
}

.cssmap-210 .pl15.active-region .bg {
    background-position: -305px -242px
}

.cssmap-210 .pl16.focus .bg, .cssmap-210 .pl16.active-region .bg {
    height: 64px;
    left: 2px;
    top: 11px;
    width: 56px
}

.cssmap-210 .pl16.focus .bg {
    background-position: -72px -393px
}

.cssmap-210 .pl16.active-region .bg {
    background-position: -372px -393px
}

.cssmap-210 .pl1 a {
    left: 51px;
    top: 126px
}

.cssmap-210 .pl1 a.tooltip-left .tooltip-arrow {
    left: 51px
}

.cssmap-210 .pl2 a {
    left: 92px;
    top: 65px
}

.cssmap-210 .pl2 a.tooltip-left .tooltip-arrow {
    left: 92px
}

.cssmap-210 .pl3 a {
    left: 175px;
    top: 122px
}

.cssmap-210 .pl3 a.tooltip-right .tooltip-arrow {
    right: 35px
}

.cssmap-210 .pl4 a {
    left: 29px;
    top: 93px
}

.cssmap-210 .pl4 a.tooltip-left .tooltip-arrow {
    left: 29px
}

.cssmap-210 .pl5 a {
    left: 110px;
    top: 111px
}

.cssmap-210 .pl5 a.tooltip-left .tooltip-arrow {
    left: 110px
}

.cssmap-210 .pl6 a {
    left: 126px;
    top: 166px
}

.cssmap-210 .pl6 a.tooltip-right .tooltip-arrow {
    right: 84px
}

.cssmap-210 .pl7 a {
    left: 143px;
    top: 84px
}

.cssmap-210 .pl7 a.tooltip-right .tooltip-arrow {
    right: 67px
}

.cssmap-210 .pl8 a {
    left: 80px;
    top: 140px
}

.cssmap-210 .pl8 a.tooltip-left .tooltip-arrow {
    left: 80px
}

.cssmap-210 .pl9 a {
    left: 163px;
    top: 162px
}

.cssmap-210 .pl9 a.tooltip-right .tooltip-arrow {
    right: 47px
}

.cssmap-210 .pl10 a {
    left: 177px;
    top: 58px
}

.cssmap-210 .pl10 a.tooltip-right .tooltip-arrow {
    right: 33px
}

.cssmap-210 .pl11 a {
    left: 81px;
    top: 27px
}

.cssmap-210 .pl11 a.tooltip-left .tooltip-arrow {
    left: 81px
}

.cssmap-210 .pl12 a {
    left: 101px;
    top: 147px
}

.cssmap-210 .pl12 a.tooltip-left .tooltip-arrow {
    left: 101px
}

.cssmap-210 .pl13 a {
    left: 137px;
    top: 138px
}

.cssmap-210 .pl13 a.tooltip-right .tooltip-arrow {
    right: 73px
}

.cssmap-210 .pl14 a {
    left: 140px;
    top: 38px
}

.cssmap-210 .pl14 a.tooltip-right .tooltip-arrow {
    right: 70px
}

.cssmap-210 .pl15 a {
    left: 67px;
    top: 91px
}

.cssmap-210 .pl15 a.tooltip-left .tooltip-arrow {
    left: 67px
}

.cssmap-210 .pl16 a {
    left: 34px;
    top: 48px
}

.cssmap-210 .pl16 a.tooltip-left .tooltip-arrow {
    left: 34px
}

/* size: 250 */
.cssmap-250 {
    width: 250px
}

.cssmap-250 .poland, .cssmap-250 .poland .bg, .cssmap-250 .poland-cities {
    background: transparent url('default/pl-250.png') no-repeat 0 0
}

/*.cssmap-250 .poland.cssmap-blue, .cssmap-250 .poland.cssmap-blue .bg, .cssmap-250 .poland-cities.cssmap-blue {*/
/*    background-image: url('blue/pl-250.png')*/
/*}*/

/*.cssmap-250 .poland.cssmap-dark, .cssmap-250 .poland.cssmap-dark .bg, .cssmap-250 .poland-cities.cssmap-dark {*/
/*    background-image: url('dark/pl-250.png')*/
/*}*/

/*.cssmap-250 .poland.cssmap-vintage, .cssmap-250 .poland.cssmap-vintage .bg, .cssmap-250 .poland-cities.cssmap-vintage {*/
/*    background-image: url('vintage/pl-250.png')*/
/*}*/

/*.cssmap-250 .poland.cssmap-custom, .cssmap-250 .poland.cssmap-custom .bg, .cssmap-250 .poland-cities.cssmap-custom {*/
/*    background-image: url('custom/pl-250.png')*/
/*}*/

.cssmap-250 .poland, .cssmap-250 .poland-cities {
    height: 240px;
    width: 250px
}

.cssmap-250 .poland-cities {
    background-position: -260px 0
}

.cssmap-250 .poland .m {
    margin: 9996px 0 0 -3px
}

.cssmap-250 .poland .m span {
    font-size: 16px !important;
    padding: 0 1px 1px 0
}

.cssmap-250 .poland .bg {
    padding: 5px
}

.cssmap-250 .pl1.focus .bg, .cssmap-250 .pl1.active-region .bg {
    height: 65px;
    left: 16px;
    top: 121px;
    width: 72px
}

.cssmap-250 .pl1.focus .bg {
    background-position: -220px -387px
}

.cssmap-250 .pl1.active-region .bg {
    background-position: -590px -387px
}

.cssmap-250 .pl2.focus .bg, .cssmap-250 .pl2.active-region .bg {
    height: 58px;
    left: 74px;
    top: 43px;
    width: 61px
}

.cssmap-250 .pl2.focus .bg {
    background-position: -295px -283px
}

.cssmap-250 .pl2.active-region .bg {
    background-position: -665px -283px
}

.cssmap-250 .pl3.focus .bg, .cssmap-250 .pl3.active-region .bg {
    height: 78px;
    left: 180px;
    top: 102px;
    width: 60px
}

.cssmap-250 .pl3.focus .bg {
    background-position: -5px -465px
}

.cssmap-250 .pl3.active-region .bg {
    background-position: -375px -465px
}

.cssmap-250 .pl4.focus .bg, .cssmap-250 .pl4.active-region .bg {
    height: 69px;
    left: 9px;
    top: 69px;
    width: 46px
}

.cssmap-250 .pl4.focus .bg {
    background-position: -311px -360px
}

.cssmap-250 .pl4.active-region .bg {
    background-position: -681px -360px
}

.cssmap-250 .pl5.focus .bg, .cssmap-250 .pl5.active-region .bg {
    height: 61px;
    left: 94px;
    top: 98px;
    width: 63px
}

.cssmap-250 .pl5.focus .bg {
    background-position: -178px -560px
}

.cssmap-250 .pl5.active-region .bg {
    background-position: -548px -560px
}

.cssmap-250 .pl6.focus .bg, .cssmap-250 .pl6.active-region .bg {
    height: 51px;
    left: 119px;
    top: 170px;
    width: 56px
}

.cssmap-250 .pl6.focus .bg {
    background-position: -106px -399px
}

.cssmap-250 .pl6.active-region .bg {
    background-position: -476px -399px
}

.cssmap-250 .pl7.focus .bg, .cssmap-250 .pl7.active-region .bg {
    height: 97px;
    left: 123px;
    top: 55px;
    width: 93px
}

.cssmap-250 .pl7.focus .bg {
    background-position: -106px -283px
}

.cssmap-250 .pl7.active-region .bg {
    background-position: -476px -283px
}

.cssmap-250 .pl8.focus .bg, .cssmap-250 .pl8.active-region .bg {
    height: 47px;
    left: 66px;
    top: 145px;
    width: 43px
}

.cssmap-250 .pl8.focus .bg {
    background-position: -4px -574px
}

.cssmap-250 .pl8.active-region .bg {
    background-position: -374px -574px
}

.cssmap-250 .pl9.focus .bg, .cssmap-250 .pl9.active-region .bg {
    height: 68px;
    left: 168px;
    top: 159px;
    width: 58px
}

.cssmap-250 .pl9.focus .bg {
    background-position: -182px -472px
}

.cssmap-250 .pl9.active-region .bg {
    background-position: -552px -472px
}

.cssmap-250 .pl10.focus .bg, .cssmap-250 .pl10.active-region .bg {
    height: 86px;
    left: 179px;
    top: 17px;
    width: 57px
}

.cssmap-250 .pl10.focus .bg {
    background-position: -219px -282px
}

.cssmap-250 .pl10.active-region .bg {
    background-position: -589px -282px
}

.cssmap-250 .pl11.focus .bg, .cssmap-250 .pl11.active-region .bg {
    height: 55px;
    left: 62px;
    top: 0;
    width: 71px
}

.cssmap-250 .pl11.focus .bg {
    background-position: -68px -569px
}

.cssmap-250 .pl11.active-region .bg {
    background-position: -438px -569px
}

.cssmap-250 .pl12.focus .bg, .cssmap-250 .pl12.active-region .bg {
    height: 64px;
    left: 93px;
    top: 149px;
    width: 47px
}

.cssmap-250 .pl12.focus .bg {
    background-position: -280px -546px
}

.cssmap-250 .pl12.active-region .bg {
    background-position: -650px -546px
}

.cssmap-250 .pl13.focus .bg, .cssmap-250 .pl13.active-region .bg {
    height: 44px;
    left: 133px;
    top: 139px;
    width: 53px
}

.cssmap-250 .pl13.focus .bg {
    background-position: -4px -402px
}

.cssmap-250 .pl13.active-region .bg {
    background-position: -374px -402px
}

.cssmap-250 .pl14.focus .bg, .cssmap-250 .pl14.active-region .bg {
    height: 53px;
    left: 120px;
    top: 16px;
    width: 89px
}

.cssmap-250 .pl14.focus .bg {
    background-position: -260px -473px
}

.cssmap-250 .pl14.active-region .bg {
    background-position: -630px -473px
}

.cssmap-250 .pl15.focus .bg, .cssmap-250 .pl15.active-region .bg {
    height: 101px;
    left: 39px;
    top: 48px;
    width: 80px
}

.cssmap-250 .pl15.focus .bg {
    background-position: -4px -282px
}

.cssmap-250 .pl15.active-region .bg {
    background-position: -374px -282px
}

.cssmap-250 .pl16.focus .bg, .cssmap-250 .pl16.active-region .bg {
    height: 79px;
    left: 0;
    top: 11px;
    width: 69px
}

.cssmap-250 .pl16.focus .bg {
    background-position: -89px -470px
}

.cssmap-250 .pl16.active-region .bg {
    background-position: -459px -470px
}

.cssmap-250 .pl1 a {
    left: 60px;
    top: 151px
}

.cssmap-250 .pl1 a.tooltip-left .tooltip-arrow {
    left: 60px
}

.cssmap-250 .pl2 a {
    left: 110px;
    top: 77px
}

.cssmap-250 .pl2 a.tooltip-left .tooltip-arrow {
    left: 110px
}

.cssmap-250 .pl3 a {
    left: 212px;
    top: 146px
}

.cssmap-250 .pl3 a.tooltip-right .tooltip-arrow {
    right: 38px
}

.cssmap-250 .pl4 a {
    left: 32px;
    top: 110px
}

.cssmap-250 .pl4 a.tooltip-left .tooltip-arrow {
    left: 32px
}

.cssmap-250 .pl5 a {
    left: 132px;
    top: 133px
}

.cssmap-250 .pl5 a.tooltip-left .tooltip-arrow {
    left: 132px
}

.cssmap-250 .pl6 a {
    left: 152px;
    top: 200px
}

.cssmap-250 .pl6 a.tooltip-left .tooltip-arrow {
    left: 152px
}

.cssmap-250 .pl7 a {
    left: 173px;
    top: 100px
}

.cssmap-250 .pl7 a.tooltip-right .tooltip-arrow {
    right: 77px
}

.cssmap-250 .pl8 a {
    left: 95px;
    top: 169px
}

.cssmap-250 .pl8 a.tooltip-left .tooltip-arrow {
    left: 95px
}

.cssmap-250 .pl9 a {
    left: 197px;
    top: 195px
}

.cssmap-250 .pl9 a.tooltip-right .tooltip-arrow {
    right: 53px
}

.cssmap-250 .pl10 a {
    left: 215px;
    top: 67px
}

.cssmap-250 .pl10 a.tooltip-right .tooltip-arrow {
    right: 35px
}

.cssmap-250 .pl11 a {
    left: 97px;
    top: 29px
}

.cssmap-250 .pl11 a.tooltip-left .tooltip-arrow {
    left: 97px
}

.cssmap-250 .pl12 a {
    left: 121px;
    top: 177px
}

.cssmap-250 .pl12 a.tooltip-left .tooltip-arrow {
    left: 121px
}

.cssmap-250 .pl13 a {
    left: 165px;
    top: 166px
}

.cssmap-250 .pl13 a.tooltip-right .tooltip-arrow {
    right: 85px
}

.cssmap-250 .pl14 a {
    left: 168px;
    top: 43px
}

.cssmap-250 .pl14 a.tooltip-right .tooltip-arrow {
    right: 82px
}

.cssmap-250 .pl15 a {
    left: 79px;
    top: 108px
}

.cssmap-250 .pl15 a.tooltip-left .tooltip-arrow {
    left: 79px
}

.cssmap-250 .pl16 a {
    left: 39px;
    top: 55px
}

.cssmap-250 .pl16 a.tooltip-left .tooltip-arrow {
    left: 39px
}

/* size: 320 */
.cssmap-320 {
    width: 320px
}

.cssmap-320 .poland, .cssmap-320 .poland .bg, .cssmap-320 .poland-cities {
    background: transparent url('default/pl-320.png') no-repeat 0 0
}

/*.cssmap-320 .poland.cssmap-blue, .cssmap-320 .poland.cssmap-blue .bg, .cssmap-320 .poland-cities.cssmap-blue {*/
/*    background-image: url('blue/pl-320.png')*/
/*}*/

/*.cssmap-320 .poland.cssmap-dark, .cssmap-320 .poland.cssmap-dark .bg, .cssmap-320 .poland-cities.cssmap-dark {*/
/*    background-image: url('dark/pl-320.png')*/
/*}*/

/*.cssmap-320 .poland.cssmap-vintage, .cssmap-320 .poland.cssmap-vintage .bg, .cssmap-320 .poland-cities.cssmap-vintage {*/
/*    background-image: url('vintage/pl-320.png')*/
/*}*/

/*.cssmap-320 .poland.cssmap-custom, .cssmap-320 .poland.cssmap-custom .bg, .cssmap-320 .poland-cities.cssmap-custom {*/
/*    background-image: url('custom/pl-320.png')*/
/*}*/

.cssmap-320 .poland, .cssmap-320 .poland-cities {
    height: 305px;
    width: 320px
}

.cssmap-320 .poland-cities {
    background-position: -330px 0
}

.cssmap-320 .poland .m {
    margin: 9999px 0 0 0
}

.cssmap-320 .poland .m span {
    font-size: 20px !important
}

.cssmap-320 .poland .bg {
    padding: 10px
}

.cssmap-320 .pl1.focus .bg, .cssmap-320 .pl1.active-region .bg {
    height: 82px;
    left: 21px;
    top: 152px;
    width: 90px
}

.cssmap-320 .pl1.focus .bg {
    background-position: -273px -484px
}

.cssmap-320 .pl1.active-region .bg {
    background-position: -743px -484px
}

.cssmap-320 .pl2.focus .bg, .cssmap-320 .pl2.active-region .bg {
    height: 73px;
    left: 93px;
    top: 54px;
    width: 76px
}

.cssmap-320 .pl2.focus .bg {
    background-position: -368px -352px
}

.cssmap-320 .pl2.active-region .bg {
    background-position: -838px -352px
}

.cssmap-320 .pl3.focus .bg, .cssmap-320 .pl3.active-region .bg {
    height: 98px;
    left: 225px;
    top: 128px;
    width: 75px
}

.cssmap-320 .pl3.focus .bg {
    background-position: -5px -581px
}

.cssmap-320 .pl3.active-region .bg {
    background-position: -475px -581px
}

.cssmap-320 .pl4.focus .bg, .cssmap-320 .pl4.active-region .bg {
    height: 86px;
    left: 12px;
    top: 87px;
    width: 57px
}

.cssmap-320 .pl4.focus .bg {
    background-position: -387px -449px
}

.cssmap-320 .pl4.active-region .bg {
    background-position: -857px -449px
}

.cssmap-320 .pl5.focus .bg, .cssmap-320 .pl5.active-region .bg {
    height: 76px;
    left: 118px;
    top: 123px;
    width: 78px
}

.cssmap-320 .pl5.focus .bg {
    background-position: -229px -700px
}

.cssmap-320 .pl5.active-region .bg {
    background-position: -699px -700px
}

.cssmap-320 .pl6.focus .bg, .cssmap-320 .pl6.active-region .bg {
    height: 63px;
    left: 149px;
    top: 213px;
    width: 70px
}

.cssmap-320 .pl6.focus .bg {
    background-position: -129px -498px
}

.cssmap-320 .pl6.active-region .bg {
    background-position: -599px -498px
}

.cssmap-320 .pl7.focus .bg, .cssmap-320 .pl7.active-region .bg {
    height: 121px;
    left: 154px;
    top: 69px;
    width: 117px
}

.cssmap-320 .pl7.focus .bg {
    background-position: -131px -352px
}

.cssmap-320 .pl7.active-region .bg {
    background-position: -601px -352px
}

.cssmap-320 .pl8.focus .bg, .cssmap-320 .pl8.active-region .bg {
    height: 59px;
    left: 83px;
    top: 181px;
    width: 54px
}

.cssmap-320 .pl8.focus .bg {
    background-position: -4px -703px
}

.cssmap-320 .pl8.active-region .bg {
    background-position: -474px -703px
}

.cssmap-320 .pl9.focus .bg, .cssmap-320 .pl9.active-region .bg {
    height: 85px;
    left: 210px;
    top: 199px;
    width: 73px
}

.cssmap-320 .pl9.focus .bg {
    background-position: -221px -590px
}

.cssmap-320 .pl9.active-region .bg {
    background-position: -691px -590px
}

.cssmap-320 .pl10.focus .bg, .cssmap-320 .pl10.active-region .bg {
    height: 108px;
    left: 224px;
    top: 21px;
    width: 71px
}

.cssmap-320 .pl10.focus .bg {
    background-position: -273px -351px
}

.cssmap-320 .pl10.active-region .bg {
    background-position: -743px -351px
}

.cssmap-320 .pl11.focus .bg, .cssmap-320 .pl11.active-region .bg {
    height: 69px;
    left: 77px;
    top: 0;
    width: 89px
}

.cssmap-320 .pl11.focus .bg {
    background-position: -105px -709px
}

.cssmap-320 .pl11.active-region .bg {
    background-position: -575px -709px
}

.cssmap-320 .pl12.focus .bg, .cssmap-320 .pl12.active-region .bg {
    height: 80px;
    left: 117px;
    top: 186px;
    width: 59px
}

.cssmap-320 .pl12.focus .bg {
    background-position: -347px -686px
}

.cssmap-320 .pl12.active-region .bg {
    background-position: -817px -686px
}

.cssmap-320 .pl13.focus .bg, .cssmap-320 .pl13.active-region .bg {
    height: 55px;
    left: 167px;
    top: 174px;
    width: 65px
}

.cssmap-320 .pl13.focus .bg {
    background-position: -5px -501px
}

.cssmap-320 .pl13.active-region .bg {
    background-position: -475px -501px
}

.cssmap-320 .pl14.focus .bg, .cssmap-320 .pl14.active-region .bg {
    height: 67px;
    left: 150px;
    top: 20px;
    width: 111px
}

.cssmap-320 .pl14.focus .bg {
    background-position: -323px -590px
}

.cssmap-320 .pl14.active-region .bg {
    background-position: -793px -590px
}

.cssmap-320 .pl15.focus .bg, .cssmap-320 .pl15.active-region .bg {
    height: 126px;
    left: 49px;
    top: 60px;
    width: 100px
}

.cssmap-320 .pl15.focus .bg {
    background-position: -4px -351px
}

.cssmap-320 .pl15.active-region .bg {
    background-position: -474px -351px
}

.cssmap-320 .pl16.focus .bg, .cssmap-320 .pl16.active-region .bg {
    height: 99px;
    left: 0;
    top: 13px;
    width: 86px
}

.cssmap-320 .pl16.focus .bg {
    background-position: -105px -585px
}

.cssmap-320 .pl16.active-region .bg {
    background-position: -575px -585px
}

.cssmap-320 .pl1 a {
    left: 79px;
    top: 193px
}

.cssmap-320 .pl1 a.tooltip-left .tooltip-arrow {
    left: 79px
}

.cssmap-320 .pl2 a {
    left: 141px;
    top: 100px
}

.cssmap-320 .pl2 a.tooltip-left .tooltip-arrow {
    left: 141px
}

.cssmap-320 .pl3 a {
    left: 269px;
    top: 187px
}

.cssmap-320 .pl3 a.tooltip-right .tooltip-arrow {
    right: 51px
}

.cssmap-320 .pl4 a {
    left: 44px;
    top: 142px
}

.cssmap-320 .pl4 a.tooltip-left .tooltip-arrow {
    left: 44px
}

.cssmap-320 .pl5 a {
    left: 169px;
    top: 170px
}

.cssmap-320 .pl5 a.tooltip-left .tooltip-arrow {
    left: 169px
}

.cssmap-320 .pl6 a {
    left: 194px;
    top: 254px
}

.cssmap-320 .pl6 a.tooltip-right .tooltip-arrow {
    right: 126px
}

.cssmap-320 .pl7 a {
    left: 220px;
    top: 129px
}

.cssmap-320 .pl7 a.tooltip-right .tooltip-arrow {
    right: 100px
}

.cssmap-320 .pl8 a {
    left: 123px;
    top: 215px
}

.cssmap-320 .pl8 a.tooltip-left .tooltip-arrow {
    left: 123px
}

.cssmap-320 .pl9 a {
    left: 250px;
    top: 248px
}

.cssmap-320 .pl9 a.tooltip-right .tooltip-arrow {
    right: 70px
}

.cssmap-320 .pl10 a {
    left: 273px;
    top: 88px
}

.cssmap-320 .pl10 a.tooltip-right .tooltip-arrow {
    right: 47px
}

.cssmap-320 .pl11 a {
    left: 125px;
    top: 41px
}

.cssmap-320 .pl11 a.tooltip-left .tooltip-arrow {
    left: 125px
}

.cssmap-320 .pl12 a {
    left: 156px;
    top: 226px
}

.cssmap-320 .pl12 a.tooltip-left .tooltip-arrow {
    left: 156px
}

.cssmap-320 .pl13 a {
    left: 210px;
    top: 212px
}

.cssmap-320 .pl13 a.tooltip-right .tooltip-arrow {
    right: 110px
}

.cssmap-320 .pl14 a {
    left: 214px;
    top: 57px
}

.cssmap-320 .pl14 a.tooltip-right .tooltip-arrow {
    right: 106px
}

.cssmap-320 .pl15 a {
    left: 103px;
    top: 140px
}

.cssmap-320 .pl15 a.tooltip-left .tooltip-arrow {
    left: 103px
}

.cssmap-320 .pl16 a {
    left: 53px;
    top: 73px
}

.cssmap-320 .pl16 a.tooltip-left .tooltip-arrow {
    left: 53px
}

/* size: 430 */
.cssmap-430 {
    width: 430px
}

.cssmap-430 .poland, .cssmap-430 .poland .bg, .cssmap-430 .poland-cities {
    background: transparent url('default/pl-430.png') no-repeat 0 0
}

/*.cssmap-430 .poland.cssmap-blue, .cssmap-430 .poland.cssmap-blue .bg, .cssmap-430 .poland-cities.cssmap-blue {*/
/*    background-image: url('blue/pl-430.png')*/
/*}*/

/*.cssmap-430 .poland.cssmap-dark, .cssmap-430 .poland.cssmap-dark .bg, .cssmap-430 .poland-cities.cssmap-dark {*/
/*    background-image: url('dark/pl-430.png')*/
/*}*/

/*.cssmap-430 .poland.cssmap-vintage, .cssmap-430 .poland.cssmap-vintage .bg, .cssmap-430 .poland-cities.cssmap-vintage {*/
/*    background-image: url('vintage/pl-430.png')*/
/*}*/

/*.cssmap-430 .poland.cssmap-custom, .cssmap-430 .poland.cssmap-custom .bg, .cssmap-430 .poland-cities.cssmap-custom {*/
/*    background-image: url('custom/pl-430.png')*/
/*}*/

.cssmap-430 .poland, .cssmap-430 .poland-cities {
    height: 410px;
    width: 430px
}

.cssmap-430 .poland-cities {
    background-position: -440px 0
}

.cssmap-430 .poland .m {
    margin: 10006px 0 0 7px
}

.cssmap-430 .poland .m span {
    font-size: 26px !important;
    padding: 0 1px 1px 0
}

.cssmap-430 .poland .bg {
    padding: 10px
}

.cssmap-430 .pl1.focus .bg, .cssmap-430 .pl1.active-region .bg {
    height: 106px;
    left: 37px;
    top: 207px;
    width: 116px
}

.cssmap-430 .pl1.focus .bg {
    background-position: -347px -625px
}

.cssmap-430 .pl1.active-region .bg {
    background-position: -947px -625px
}

.cssmap-430 .pl2.focus .bg, .cssmap-430 .pl2.active-region .bg {
    height: 94px;
    left: 131px;
    top: 80px;
    width: 99px
}

.cssmap-430 .pl2.focus .bg {
    background-position: -470px -456px
}

.cssmap-430 .pl2.active-region .bg {
    background-position: -1070px -456px
}

.cssmap-430 .pl3.focus .bg, .cssmap-430 .pl3.active-region .bg {
    height: 127px;
    left: 302px;
    top: 177px;
    width: 98px
}

.cssmap-430 .pl3.focus .bg {
    background-position: -4px -751px
}

.cssmap-430 .pl3.active-region .bg {
    background-position: -604px -751px
}

.cssmap-430 .pl4.focus .bg, .cssmap-430 .pl4.active-region .bg {
    height: 112px;
    left: 25px;
    top: 123px;
    width: 74px
}

.cssmap-430 .pl4.focus .bg {
    background-position: -492px -580px
}

.cssmap-430 .pl4.active-region .bg {
    background-position: -1092px -580px
}

.cssmap-430 .pl5.focus .bg, .cssmap-430 .pl5.active-region .bg {
    height: 98px;
    left: 164px;
    top: 170px;
    width: 101px
}

.cssmap-430 .pl5.focus .bg {
    background-position: -284px -901px
}

.cssmap-430 .pl5.active-region .bg {
    background-position: -884px -901px
}

.cssmap-430 .pl6.focus .bg, .cssmap-430 .pl6.active-region .bg {
    height: 82px;
    left: 204px;
    top: 287px;
    width: 91px
}

.cssmap-430 .pl6.focus .bg {
    background-position: -174px -644px
}

.cssmap-430 .pl6.active-region .bg {
    background-position: -774px -644px
}

.cssmap-430 .pl7.focus .bg, .cssmap-430 .pl7.active-region .bg {
    height: 157px;
    left: 210px;
    top: 100px;
    width: 151px
}

.cssmap-430 .pl7.focus .bg {
    background-position: -166px -457px
}

.cssmap-430 .pl7.active-region .bg {
    background-position: -766px -457px
}

.cssmap-430 .pl8.focus .bg, .cssmap-430 .pl8.active-region .bg {
    height: 76px;
    left: 118px;
    top: 245px;
    width: 70px
}

.cssmap-430 .pl8.focus .bg {
    background-position: -4px -907px
}

.cssmap-430 .pl8.active-region .bg {
    background-position: -604px -907px
}

.cssmap-430 .pl9.focus .bg, .cssmap-430 .pl9.active-region .bg {
    height: 110px;
    left: 283px;
    top: 269px;
    width: 94px
}

.cssmap-430 .pl9.focus .bg {
    background-position: -284px -761px
}

.cssmap-430 .pl9.active-region .bg {
    background-position: -884px -761px
}

.cssmap-430 .pl10.focus .bg, .cssmap-430 .pl10.active-region .bg {
    height: 140px;
    left: 301px;
    top: 38px;
    width: 93px
}

.cssmap-430 .pl10.focus .bg {
    background-position: -348px -456px
}

.cssmap-430 .pl10.active-region .bg {
    background-position: -948px -456px
}

.cssmap-430 .pl11.focus .bg, .cssmap-430 .pl11.active-region .bg {
    height: 90px;
    left: 110px;
    top: 10px;
    width: 115px
}

.cssmap-430 .pl11.focus .bg {
    background-position: -104px -914px
}

.cssmap-430 .pl11.active-region .bg {
    background-position: -704px -914px
}

.cssmap-430 .pl12.focus .bg, .cssmap-430 .pl12.active-region .bg {
    height: 104px;
    left: 162px;
    top: 252px;
    width: 76px
}

.cssmap-430 .pl12.focus .bg {
    background-position: -432px -882px
}

.cssmap-430 .pl12.active-region .bg {
    background-position: -1032px -882px
}

.cssmap-430 .pl13.focus .bg, .cssmap-430 .pl13.active-region .bg {
    height: 72px;
    left: 227px;
    top: 236px;
    width: 85px
}

.cssmap-430 .pl13.focus .bg {
    background-position: -4px -649px
}

.cssmap-430 .pl13.active-region .bg {
    background-position: -604px -649px
}

.cssmap-430 .pl14.focus .bg, .cssmap-430 .pl14.active-region .bg {
    height: 86px;
    left: 205px;
    top: 36px;
    width: 144px
}

.cssmap-430 .pl14.focus .bg {
    background-position: -408px -760px
}

.cssmap-430 .pl14.active-region .bg {
    background-position: -1008px -760px
}

.cssmap-430 .pl15.focus .bg, .cssmap-430 .pl15.active-region .bg {
    height: 163px;
    left: 74px;
    top: 88px;
    width: 130px
}

.cssmap-430 .pl15.focus .bg {
    background-position: -4px -456px
}

.cssmap-430 .pl15.active-region .bg {
    background-position: -604px -456px
}

.cssmap-430 .pl16.focus .bg, .cssmap-430 .pl16.active-region .bg {
    height: 128px;
    left: 10px;
    top: 28px;
    width: 112px
}

.cssmap-430 .pl16.focus .bg {
    background-position: -132px -756px
}

.cssmap-430 .pl16.active-region .bg {
    background-position: -732px -756px
}

.cssmap-430 .pl1 a {
    left: 109px;
    top: 258px
}

.cssmap-430 .pl1 a.tooltip-left .tooltip-arrow {
    left: 109px
}

.cssmap-430 .pl2 a {
    left: 191px;
    top: 137px
}

.cssmap-430 .pl2 a.tooltip-left .tooltip-arrow {
    left: 191px
}

.cssmap-430 .pl3 a {
    left: 357px;
    top: 250px
}

.cssmap-430 .pl3 a.tooltip-right .tooltip-arrow {
    right: 73px
}

.cssmap-430 .pl4 a {
    left: 65px;
    top: 191px
}

.cssmap-430 .pl4 a.tooltip-left .tooltip-arrow {
    left: 65px
}

.cssmap-430 .pl5 a {
    left: 227px;
    top: 228px
}

.cssmap-430 .pl5 a.tooltip-left .tooltip-arrow {
    left: 227px
}

.cssmap-430 .pl6 a {
    left: 259px;
    top: 337px
}

.cssmap-430 .pl6 a.tooltip-left .tooltip-arrow {
    left: 259px
}

.cssmap-430 .pl7 a {
    left: 293px;
    top: 175px
}

.cssmap-430 .pl7 a.tooltip-right .tooltip-arrow {
    right: 137px
}

.cssmap-430 .pl8 a {
    left: 166px;
    top: 286px
}

.cssmap-430 .pl8 a.tooltip-left .tooltip-arrow {
    left: 166px
}

.cssmap-430 .pl9 a {
    left: 332px;
    top: 329px
}

.cssmap-430 .pl9 a.tooltip-right .tooltip-arrow {
    right: 98px
}

.cssmap-430 .pl10 a {
    left: 361px;
    top: 122px
}

.cssmap-430 .pl10 a.tooltip-right .tooltip-arrow {
    right: 69px
}

.cssmap-430 .pl11 a {
    left: 169px;
    top: 60px
}

.cssmap-430 .pl11 a.tooltip-left .tooltip-arrow {
    left: 169px
}

.cssmap-430 .pl12 a {
    left: 209px;
    top: 301px
}

.cssmap-430 .pl12 a.tooltip-left .tooltip-arrow {
    left: 209px
}

.cssmap-430 .pl13 a {
    left: 280px;
    top: 282px
}

.cssmap-430 .pl13 a.tooltip-right .tooltip-arrow {
    right: 150px
}

.cssmap-430 .pl14 a {
    left: 286px;
    top: 91px
}

.cssmap-430 .pl14 a.tooltip-right .tooltip-arrow {
    right: 144px
}

.cssmap-430 .pl15 a {
    left: 140px;
    top: 188px
}

.cssmap-430 .pl15 a.tooltip-left .tooltip-arrow {
    left: 140px
}

.cssmap-430 .pl16 a {
    left: 75px;
    top: 101px
}

.cssmap-430 .pl16 a.tooltip-left .tooltip-arrow {
    left: 75px
}

/* size: 540 */
.cssmap-540 {
    width: 540px
}

.cssmap-540 .poland, .cssmap-540 .poland .bg, .cssmap-540 .poland-cities {
    background: transparent url('default/pl-540.png') no-repeat 0 0
}

/*.cssmap-540 .poland.cssmap-blue, .cssmap-540 .poland.cssmap-blue .bg, .cssmap-540 .poland-cities.cssmap-blue {*/
/*    background-image: url('blue/pl-540.png')*/
/*}*/

/*.cssmap-540 .poland.cssmap-dark, .cssmap-540 .poland.cssmap-dark .bg, .cssmap-540 .poland-cities.cssmap-dark {*/
/*    background-image: url('dark/pl-540.png')*/
/*}*/

/*.cssmap-540 .poland.cssmap-vintage, .cssmap-540 .poland.cssmap-vintage .bg, .cssmap-540 .poland-cities.cssmap-vintage {*/
/*    background-image: url('vintage/pl-540.png')*/
/*}*/

/*.cssmap-540 .poland.cssmap-custom, .cssmap-540 .poland.cssmap-custom .bg, .cssmap-540 .poland-cities.cssmap-custom {*/
/*    background-image: url('custom/pl-540.png')*/
/*}*/

.cssmap-540 .poland, .cssmap-540 .poland-cities {
    height: 515px;
    width: 540px
}

.cssmap-540 .poland-cities {
    background-position: -550px 0
}

.cssmap-540 .poland .m {
    margin: 10005px 0 0 5px
}

.cssmap-540 .poland .m span {
    font-size: 33px !important;
    padding: 0 1px 1px 0
}

.cssmap-540 .poland .bg {
    padding: 10px
}

.cssmap-540 .pl1.focus .bg, .cssmap-540 .pl1.active-region .bg {
    height: 135px;
    left: 46px;
    top: 263px;
    width: 147px
}

.cssmap-540 .pl1.focus .bg {
    background-position: -426px -773px
}

.cssmap-540 .pl1.active-region .bg {
    background-position: -1146px -773px
}

.cssmap-540 .pl2.focus .bg, .cssmap-540 .pl2.active-region .bg {
    height: 120px;
    left: 166px;
    top: 101px;
    width: 125px
}

.cssmap-540 .pl2.focus .bg {
    background-position: -573px -564px
}

.cssmap-540 .pl2.active-region .bg {
    background-position: -1293px -564px
}

.cssmap-540 .pl3.focus .bg, .cssmap-540 .pl3.active-region .bg {
    height: 161px;
    left: 383px;
    top: 224px;
    width: 124px
}

.cssmap-540 .pl3.focus .bg {
    background-position: -10px -922px
}

.cssmap-540 .pl3.active-region .bg {
    background-position: -730px -922px
}

.cssmap-540 .pl4.focus .bg, .cssmap-540 .pl4.active-region .bg {
    height: 142px;
    left: 32px;
    top: 156px;
    width: 93px
}

.cssmap-540 .pl4.focus .bg {
    background-position: -606px -714px
}

.cssmap-540 .pl4.active-region .bg {
    background-position: -1326px -714px
}

.cssmap-540 .pl5.focus .bg, .cssmap-540 .pl5.active-region .bg {
    height: 125px;
    left: 207px;
    top: 215px;
    width: 129px
}

.cssmap-540 .pl5.focus .bg {
    background-position: -363px -1107px
}

.cssmap-540 .pl5.active-region .bg {
    background-position: -1083px -1107px
}

.cssmap-540 .pl6.focus .bg, .cssmap-540 .pl6.active-region .bg {
    height: 104px;
    left: 258px;
    top: 364px;
    width: 115px
}

.cssmap-540 .pl6.focus .bg {
    background-position: -205px -793px
}

.cssmap-540 .pl6.active-region .bg {
    background-position: -925px -793px
}

.cssmap-540 .pl7.focus .bg, .cssmap-540 .pl7.active-region .bg {
    height: 200px;
    left: 266px;
    top: 126px;
    width: 192px
}

.cssmap-540 .pl7.focus .bg {
    background-position: -205px -564px
}

.cssmap-540 .pl7.active-region .bg {
    background-position: -925px -564px
}

.cssmap-540 .pl8.focus .bg, .cssmap-540 .pl8.active-region .bg {
    height: 97px;
    left: 150px;
    top: 311px;
    width: 89px
}

.cssmap-540 .pl8.focus .bg {
    background-position: -10px -1122px
}

.cssmap-540 .pl8.active-region .bg {
    background-position: -730px -1122px
}

.cssmap-540 .pl9.focus .bg, .cssmap-540 .pl9.active-region .bg {
    height: 140px;
    left: 359px;
    top: 341px;
    width: 120px
}

.cssmap-540 .pl9.focus .bg {
    background-position: -360px -937px
}

.cssmap-540 .pl9.active-region .bg {
    background-position: -1080px -937px
}

.cssmap-540 .pl10.focus .bg, .cssmap-540 .pl10.active-region .bg {
    height: 177px;
    left: 381px;
    top: 48px;
    width: 117px
}

.cssmap-540 .pl10.focus .bg {
    background-position: -426px -566px
}

.cssmap-540 .pl10.active-region .bg {
    background-position: -1146px -566px
}

.cssmap-540 .pl11.focus .bg, .cssmap-540 .pl11.active-region .bg {
    height: 114px;
    left: 139px;
    top: 12px;
    width: 146px
}

.cssmap-540 .pl11.focus .bg {
    background-position: -163px -1120px
}

.cssmap-540 .pl11.active-region .bg {
    background-position: -883px -1120px
}

.cssmap-540 .pl12.focus .bg, .cssmap-540 .pl12.active-region .bg {
    height: 132px;
    left: 205px;
    top: 320px;
    width: 96px
}

.cssmap-540 .pl12.focus .bg {
    background-position: -541px -1087px
}

.cssmap-540 .pl12.active-region .bg {
    background-position: -1261px -1087px
}

.cssmap-540 .pl13.focus .bg, .cssmap-540 .pl13.active-region .bg {
    height: 91px;
    left: 288px;
    top: 300px;
    width: 108px
}

.cssmap-540 .pl13.focus .bg {
    background-position: -10px -802px
}

.cssmap-540 .pl13.active-region .bg {
    background-position: -730px -802px
}

.cssmap-540 .pl14.focus .bg, .cssmap-540 .pl14.active-region .bg {
    height: 110px;
    left: 259px;
    top: 45px;
    width: 182px
}

.cssmap-540 .pl14.focus .bg {
    background-position: -508px -937px
}

.cssmap-540 .pl14.active-region .bg {
    background-position: -1228px -937px
}

.cssmap-540 .pl15.focus .bg, .cssmap-540 .pl15.active-region .bg {
    height: 208px;
    left: 94px;
    top: 112px;
    width: 165px
}

.cssmap-540 .pl15.focus .bg {
    background-position: -10px -565px
}

.cssmap-540 .pl15.active-region .bg {
    background-position: -730px -565px
}

.cssmap-540 .pl16.focus .bg, .cssmap-540 .pl16.active-region .bg {
    height: 163px;
    left: 12px;
    top: 34px;
    width: 142px
}

.cssmap-540 .pl16.focus .bg {
    background-position: -174px -926px
}

.cssmap-540 .pl16.active-region .bg {
    background-position: -894px -926px
}

.cssmap-540 .pl1 a {
    left: 135px;
    top: 325px
}

.cssmap-540 .pl1 a.tooltip-left .tooltip-arrow {
    left: 135px
}

.cssmap-540 .pl2 a {
    left: 239px;
    top: 171px
}

.cssmap-540 .pl2 a.tooltip-left .tooltip-arrow {
    left: 239px
}

.cssmap-540 .pl3 a {
    left: 449px;
    top: 314px
}

.cssmap-540 .pl3 a.tooltip-right .tooltip-arrow {
    right: 91px
}

.cssmap-540 .pl4 a {
    left: 79px;
    top: 240px
}

.cssmap-540 .pl4 a.tooltip-left .tooltip-arrow {
    left: 79px
}

.cssmap-540 .pl5 a {
    left: 284px;
    top: 287px
}

.cssmap-540 .pl5 a.tooltip-left .tooltip-arrow {
    left: 284px
}

.cssmap-540 .pl6 a {
    left: 325px;
    top: 426px
}

.cssmap-540 .pl6 a.tooltip-right .tooltip-arrow {
    right: 215px
}

.cssmap-540 .pl7 a {
    left: 369px;
    top: 219px
}

.cssmap-540 .pl7 a.tooltip-right .tooltip-arrow {
    right: 171px
}

.cssmap-540 .pl8 a {
    left: 208px;
    top: 361px
}

.cssmap-540 .pl8 a.tooltip-left .tooltip-arrow {
    left: 208px
}

.cssmap-540 .pl9 a {
    left: 418px;
    top: 415px
}

.cssmap-540 .pl9 a.tooltip-right .tooltip-arrow {
    right: 122px
}

.cssmap-540 .pl10 a {
    left: 455px;
    top: 151px
}

.cssmap-540 .pl10 a.tooltip-right .tooltip-arrow {
    right: 85px
}

.cssmap-540 .pl11 a {
    left: 211px;
    top: 73px
}

.cssmap-540 .pl11 a.tooltip-left .tooltip-arrow {
    left: 211px
}

.cssmap-540 .pl12 a {
    left: 262px;
    top: 379px
}

.cssmap-540 .pl12 a.tooltip-left .tooltip-arrow {
    left: 262px
}

.cssmap-540 .pl13 a {
    left: 352px;
    top: 355px
}

.cssmap-540 .pl13 a.tooltip-right .tooltip-arrow {
    right: 188px
}

.cssmap-540 .pl14 a {
    left: 359px;
    top: 100px
}

.cssmap-540 .pl14 a.tooltip-right .tooltip-arrow {
    right: 181px
}

.cssmap-540 .pl15 a {
    left: 175px;
    top: 236px
}

.cssmap-540 .pl15 a.tooltip-left .tooltip-arrow {
    left: 175px
}

.cssmap-540 .pl16 a {
    left: 92px;
    top: 126px
}

.cssmap-540 .pl16 a.tooltip-left .tooltip-arrow {
    left: 92px
}

/* size: 650 */
.cssmap-650 {
    width: 650px
}

.cssmap-650 .poland, .cssmap-650 .poland .bg, .cssmap-650 .poland-cities {
    background: transparent url('default/pl-650.png') no-repeat 0 0
}

/*.cssmap-650 .poland.cssmap-blue, .cssmap-650 .poland.cssmap-blue .bg, .cssmap-650 .poland-cities.cssmap-blue {*/
/*    background-image: url('blue/pl-650.png')*/
/*}*/

/*.cssmap-650 .poland.cssmap-dark, .cssmap-650 .poland.cssmap-dark .bg, .cssmap-650 .poland-cities.cssmap-dark {*/
/*    background-image: url('dark/pl-650.png')*/
/*}*/

/*.cssmap-650 .poland.cssmap-vintage, .cssmap-650 .poland.cssmap-vintage .bg, .cssmap-650 .poland-cities.cssmap-vintage {*/
/*    background-image: url('vintage/pl-650.png')*/
/*}*/

/*.cssmap-650 .poland.cssmap-custom, .cssmap-650 .poland.cssmap-custom .bg, .cssmap-650 .poland-cities.cssmap-custom {*/
/*    background-image: url('custom/pl-650.png')*/
/*}*/

.cssmap-650 .poland, .cssmap-650 .poland-cities {
    height: 620px;
    width: 650px
}

.cssmap-650 .poland-cities {
    background-position: -660px 0
}

.cssmap-650 .poland .m {
    margin: 10004px 0 0 5px
}

.cssmap-650 .poland .m span {
    font-size: 40px !important
}

.cssmap-650 .poland .bg {
    padding: 10px
}

.cssmap-650 .pl1.focus .bg, .cssmap-650 .pl1.active-region .bg {
    height: 163px;
    left: 57px;
    top: 319px;
    width: 179px
}

.cssmap-650 .pl1.focus .bg {
    background-position: -501px -917px
}

.cssmap-650 .pl1.active-region .bg {
    background-position: -1351px -917px
}

.cssmap-650 .pl2.focus .bg, .cssmap-650 .pl2.active-region .bg {
    height: 145px;
    left: 202px;
    top: 123px;
    width: 151px
}

.cssmap-650 .pl2.focus .bg {
    background-position: -674px -673px
}

.cssmap-650 .pl2.active-region .bg {
    background-position: -1524px -673px
}

.cssmap-650 .pl3.focus .bg, .cssmap-650 .pl3.active-region .bg {
    height: 195px;
    left: 465px;
    top: 272px;
    width: 150px
}

.cssmap-650 .pl3.focus .bg {
    background-position: -10px -1092px
}

.cssmap-650 .pl3.active-region .bg {
    background-position: -860px -1092px
}

.cssmap-650 .pl4.focus .bg, .cssmap-650 .pl4.active-region .bg {
    height: 172px;
    left: 39px;
    top: 189px;
    width: 113px
}

.cssmap-650 .pl4.focus .bg {
    background-position: -707px -867px
}

.cssmap-650 .pl4.active-region .bg {
    background-position: -1557px -867px
}

.cssmap-650 .pl5.focus .bg, .cssmap-650 .pl5.active-region .bg {
    height: 151px;
    left: 252px;
    top: 261px;
    width: 156px
}

.cssmap-650 .pl5.focus .bg {
    background-position: -435px -1309px
}

.cssmap-650 .pl5.active-region .bg {
    background-position: -1285px -1309px
}

.cssmap-650 .pl6.focus .bg, .cssmap-650 .pl6.active-region .bg {
    height: 126px;
    left: 313px;
    top: 441px;
    width: 140px
}

.cssmap-650 .pl6.focus .bg {
    background-position: -247px -945px
}

.cssmap-650 .pl6.active-region .bg {
    background-position: -1097px -945px
}

.cssmap-650 .pl7.focus .bg, .cssmap-650 .pl7.active-region .bg {
    height: 242px;
    left: 323px;
    top: 153px;
    width: 233px
}

.cssmap-650 .pl7.focus .bg {
    background-position: -239px -672px
}

.cssmap-650 .pl7.active-region .bg {
    background-position: -1089px -672px
}

.cssmap-650 .pl8.focus .bg, .cssmap-650 .pl8.active-region .bg {
    height: 117px;
    left: 182px;
    top: 377px;
    width: 108px
}

.cssmap-650 .pl8.focus .bg {
    background-position: -10px -1316px
}

.cssmap-650 .pl8.active-region .bg {
    background-position: -860px -1316px
}

.cssmap-650 .pl9.focus .bg, .cssmap-650 .pl9.active-region .bg {
    height: 170px;
    left: 435px;
    top: 413px;
    width: 145px
}

.cssmap-650 .pl9.focus .bg {
    background-position: -418px -1109px
}

.cssmap-650 .pl9.active-region .bg {
    background-position: -1268px -1109px
}

.cssmap-650 .pl10.focus .bg, .cssmap-650 .pl10.active-region .bg {
    height: 215px;
    left: 463px;
    top: 58px;
    width: 142px
}

.cssmap-650 .pl10.focus .bg {
    background-position: -501px -672px
}

.cssmap-650 .pl10.active-region .bg {
    background-position: -1351px -672px
}

.cssmap-650 .pl11.focus .bg, .cssmap-650 .pl11.active-region .bg {
    height: 138px;
    left: 170px;
    top: 15px;
    width: 177px
}

.cssmap-650 .pl11.focus .bg {
    background-position: -190px -1330px
}

.cssmap-650 .pl11.active-region .bg {
    background-position: -1040px -1330px
}

.cssmap-650 .pl12.focus .bg, .cssmap-650 .pl12.active-region .bg {
    height: 160px;
    left: 249px;
    top: 388px;
    width: 117px
}

.cssmap-650 .pl12.focus .bg {
    background-position: -640px -1285px
}

.cssmap-650 .pl12.active-region .bg {
    background-position: -1490px -1285px
}

.cssmap-650 .pl13.focus .bg, .cssmap-650 .pl13.active-region .bg {
    height: 110px;
    left: 349px;
    top: 364px;
    width: 130px
}

.cssmap-650 .pl13.focus .bg {
    background-position: -9px -953px
}

.cssmap-650 .pl13.active-region .bg {
    background-position: -859px -953px
}

.cssmap-650 .pl14.focus .bg, .cssmap-650 .pl14.active-region .bg {
    height: 133px;
    left: 315px;
    top: 55px;
    width: 221px
}

.cssmap-650 .pl14.focus .bg {
    background-position: -591px -1109px
}

.cssmap-650 .pl14.active-region .bg {
    background-position: -1441px -1109px
}

.cssmap-650 .pl15.focus .bg, .cssmap-650 .pl15.active-region .bg {
    height: 251px;
    left: 114px;
    top: 136px;
    width: 200px
}

.cssmap-650 .pl15.focus .bg {
    background-position: -9px -673px
}

.cssmap-650 .pl15.active-region .bg {
    background-position: -859px -673px
}

.cssmap-650 .pl16.focus .bg, .cssmap-650 .pl16.active-region .bg {
    height: 197px;
    left: 15px;
    top: 42px;
    width: 172px
}

.cssmap-650 .pl16.focus .bg {
    background-position: -204px -1102px
}

.cssmap-650 .pl16.active-region .bg {
    background-position: -1054px -1102px
}

.cssmap-650 .pl1 a {
    left: 163px;
    top: 392px
}

.cssmap-650 .pl1 a.tooltip-left .tooltip-arrow {
    left: 163px
}

.cssmap-650 .pl2 a {
    left: 288px;
    top: 205px
}

.cssmap-650 .pl2 a.tooltip-left .tooltip-arrow {
    left: 288px
}

.cssmap-650 .pl3 a {
    left: 543px;
    top: 379px
}

.cssmap-650 .pl3 a.tooltip-right .tooltip-arrow {
    right: 107px
}

.cssmap-650 .pl4 a {
    left: 94px;
    top: 289px
}

.cssmap-650 .pl4 a.tooltip-left .tooltip-arrow {
    left: 94px
}

.cssmap-650 .pl5 a {
    left: 343px;
    top: 346px
}

.cssmap-650 .pl5 a.tooltip-right .tooltip-arrow {
    right: 307px
}

.cssmap-650 .pl6 a {
    left: 393px;
    top: 514px
}

.cssmap-650 .pl6 a.tooltip-right .tooltip-arrow {
    right: 257px
}

.cssmap-650 .pl7 a {
    left: 445px;
    top: 264px
}

.cssmap-650 .pl7 a.tooltip-right .tooltip-arrow {
    right: 205px
}

.cssmap-650 .pl8 a {
    left: 251px;
    top: 435px
}

.cssmap-650 .pl8 a.tooltip-left .tooltip-arrow {
    left: 251px
}

.cssmap-650 .pl9 a {
    left: 505px;
    top: 501px
}

.cssmap-650 .pl9 a.tooltip-right .tooltip-arrow {
    right: 145px
}

.cssmap-650 .pl10 a {
    left: 551px;
    top: 182px
}

.cssmap-650 .pl10 a.tooltip-right .tooltip-arrow {
    right: 99px
}

.cssmap-650 .pl11 a {
    left: 255px;
    top: 87px
}

.cssmap-650 .pl11 a.tooltip-left .tooltip-arrow {
    left: 255px
}

.cssmap-650 .pl12 a {
    left: 317px;
    top: 457px
}

.cssmap-650 .pl12 a.tooltip-left .tooltip-arrow {
    left: 317px
}

.cssmap-650 .pl13 a {
    left: 425px;
    top: 429px
}

.cssmap-650 .pl13 a.tooltip-right .tooltip-arrow {
    right: 225px
}

.cssmap-650 .pl14 a {
    left: 434px;
    top: 120px
}

.cssmap-650 .pl14 a.tooltip-right .tooltip-arrow {
    right: 216px
}

.cssmap-650 .pl15 a {
    left: 211px;
    top: 285px
}

.cssmap-650 .pl15 a.tooltip-left .tooltip-arrow {
    left: 211px
}

.cssmap-650 .pl16 a {
    left: 111px;
    top: 151px
}

.cssmap-650 .pl16 a.tooltip-left .tooltip-arrow {
    left: 111px
}

/* size: 750 */
.cssmap-750 {
    width: 750px
}

.cssmap-750 .poland, .cssmap-750 .poland .bg, .cssmap-750 .poland-cities {
    background: transparent url('default/pl-750.png') no-repeat 0 0
}

/*.cssmap-750 .poland.cssmap-blue, .cssmap-750 .poland.cssmap-blue .bg, .cssmap-750 .poland-cities.cssmap-blue {*/
/*    background-image: url('blue/pl-750.png')*/
/*}*/

/*.cssmap-750 .poland.cssmap-dark, .cssmap-750 .poland.cssmap-dark .bg, .cssmap-750 .poland-cities.cssmap-dark {*/
/*    background-image: url('dark/pl-750.png')*/
/*}*/

/*.cssmap-750 .poland.cssmap-vintage, .cssmap-750 .poland.cssmap-vintage .bg, .cssmap-750 .poland-cities.cssmap-vintage {*/
/*    background-image: url('vintage/pl-750.png')*/
/*}*/

/*.cssmap-750 .poland.cssmap-custom, .cssmap-750 .poland.cssmap-custom .bg, .cssmap-750 .poland-cities.cssmap-custom {*/
/*    background-image: url('custom/pl-750.png')*/
/*}*/

.cssmap-750 .poland, .cssmap-750 .poland-cities {
    height: 710px;
    width: 750px
}

.cssmap-750 .poland-cities {
    background-position: -760px 0
}

.cssmap-750 .poland .m {
    margin: 9997px 0 0 -2px
}

.cssmap-750 .poland .m span {
    font-size: 47px !important;
    padding: 0 1px 1px 0
}

.cssmap-750 .poland .bg {
    padding: 10px
}

.cssmap-750 .pl1.focus .bg, .cssmap-750 .pl1.active-region .bg {
    height: 191px;
    left: 61px;
    top: 369px;
    width: 210px
}

.cssmap-750 .pl1.focus .bg {
    background-position: -578px -1044px
}

.cssmap-750 .pl1.active-region .bg {
    background-position: -1548px -1044px
}

.cssmap-750 .pl2.focus .bg, .cssmap-750 .pl2.active-region .bg {
    height: 170px;
    left: 232px;
    top: 139px;
    width: 178px
}

.cssmap-750 .pl2.focus .bg {
    background-position: -774px -763px
}

.cssmap-750 .pl2.active-region .bg {
    background-position: -1744px -763px
}

.cssmap-750 .pl3.focus .bg, .cssmap-750 .pl3.active-region .bg {
    height: 229px;
    left: 541px;
    top: 314px;
    width: 177px
}

.cssmap-750 .pl3.focus .bg {
    background-position: -10px -1247px
}

.cssmap-750 .pl3.active-region .bg {
    background-position: -980px -1247px
}

.cssmap-750 .pl4.focus .bg, .cssmap-750 .pl4.active-region .bg {
    height: 202px;
    left: 40px;
    top: 217px;
    width: 133px
}

.cssmap-750 .pl4.focus .bg {
    background-position: -817px -983px
}

.cssmap-750 .pl4.active-region .bg {
    background-position: -1787px -983px
}

.cssmap-750 .pl5.focus .bg, .cssmap-750 .pl5.active-region .bg {
    height: 177px;
    left: 290px;
    top: 301px;
    width: 183px
}

.cssmap-750 .pl5.focus .bg {
    background-position: -472px -1494px
}

.cssmap-750 .pl5.active-region .bg {
    background-position: -1442px -1494px
}

.cssmap-750 .pl6.focus .bg, .cssmap-750 .pl6.active-region .bg {
    height: 148px;
    left: 362px;
    top: 513px;
    width: 164px
}

.cssmap-750 .pl6.focus .bg {
    background-position: -285px -1077px
}

.cssmap-750 .pl6.active-region .bg {
    background-position: -1255px -1077px
}

.cssmap-750 .pl7.focus .bg, .cssmap-750 .pl7.active-region .bg {
    height: 284px;
    left: 373px;
    top: 174px;
    width: 273px
}

.cssmap-750 .pl7.focus .bg {
    background-position: -275px -763px
}

.cssmap-750 .pl7.active-region .bg {
    background-position: -1245px -763px
}

.cssmap-750 .pl8.focus .bg, .cssmap-750 .pl8.active-region .bg {
    height: 138px;
    left: 208px;
    top: 438px;
    width: 127px
}

.cssmap-750 .pl8.focus .bg {
    background-position: -9px -1505px
}

.cssmap-750 .pl8.active-region .bg {
    background-position: -979px -1505px
}

.cssmap-750 .pl9.focus .bg, .cssmap-750 .pl9.active-region .bg {
    height: 199px;
    left: 506px;
    top: 480px;
    width: 170px
}

.cssmap-750 .pl9.focus .bg {
    background-position: -467px -1265px
}

.cssmap-750 .pl9.active-region .bg {
    background-position: -1437px -1265px
}

.cssmap-750 .pl10.focus .bg, .cssmap-750 .pl10.active-region .bg {
    height: 252px;
    left: 538px;
    top: 63px;
    width: 167px
}

.cssmap-750 .pl10.focus .bg {
    background-position: -577px -763px
}

.cssmap-750 .pl10.active-region .bg {
    background-position: -1547px -763px
}

.cssmap-750 .pl11.focus .bg, .cssmap-750 .pl11.active-region .bg {
    height: 162px;
    left: 194px;
    top: 12px;
    width: 208px
}

.cssmap-750 .pl11.focus .bg {
    background-position: -226px -1515px
}

.cssmap-750 .pl11.active-region .bg {
    background-position: -1196px -1515px
}

.cssmap-750 .pl12.focus .bg, .cssmap-750 .pl12.active-region .bg {
    height: 187px;
    left: 288px;
    top: 450px;
    width: 137px
}

.cssmap-750 .pl12.focus .bg {
    background-position: -715px -1462px
}

.cssmap-750 .pl12.active-region .bg {
    background-position: -1685px -1462px
}

.cssmap-750 .pl13.focus .bg, .cssmap-750 .pl13.active-region .bg {
    height: 129px;
    left: 405px;
    top: 422px;
    width: 153px
}

.cssmap-750 .pl13.focus .bg {
    background-position: -10px -1089px
}

.cssmap-750 .pl13.active-region .bg {
    background-position: -980px -1089px
}

.cssmap-750 .pl14.focus .bg, .cssmap-750 .pl14.active-region .bg {
    height: 156px;
    left: 365px;
    top: 60px;
    width: 259px
}

.cssmap-750 .pl14.focus .bg {
    background-position: -677px -1265px
}

.cssmap-750 .pl14.active-region .bg {
    background-position: -1647px -1265px
}

.cssmap-750 .pl15.focus .bg, .cssmap-750 .pl15.active-region .bg {
    height: 295px;
    left: 129px;
    top: 154px;
    width: 234px
}

.cssmap-750 .pl15.focus .bg {
    background-position: -10px -764px
}

.cssmap-750 .pl15.active-region .bg {
    background-position: -980px -764px
}

.cssmap-750 .pl16.focus .bg, .cssmap-750 .pl16.active-region .bg {
    height: 231px;
    left: 12px;
    top: 44px;
    width: 202px
}

.cssmap-750 .pl16.focus .bg {
    background-position: -226px -1254px
}

.cssmap-750 .pl16.active-region .bg {
    background-position: -1196px -1254px
}

.cssmap-750 .pl1 a {
    left: 184px;
    top: 453px
}

.cssmap-750 .pl1 a.tooltip-left .tooltip-arrow {
    left: 184px
}

.cssmap-750 .pl2 a {
    left: 331px;
    top: 234px
}

.cssmap-750 .pl3 a {
    left: 631px;
    top: 438px
}

.cssmap-750 .pl3 a.tooltip-right .tooltip-arrow {
    right: 119px
}

.cssmap-750 .pl4 a {
    left: 103px;
    top: 332px
}

.cssmap-750 .pl4 a.tooltip-left .tooltip-arrow {
    left: 103px
}

.cssmap-750 .pl5 a {
    left: 396px;
    top: 399px
}

.cssmap-750 .pl6 a {
    left: 454px;
    top: 596px
}

.cssmap-750 .pl6 a.tooltip-right .tooltip-arrow {
    right: 296px
}

.cssmap-750 .pl7 a {
    left: 516px;
    top: 303px
}

.cssmap-750 .pl7 a.tooltip-right .tooltip-arrow {
    right: 234px
}

.cssmap-750 .pl8 a {
    left: 287px;
    top: 504px
}

.cssmap-750 .pl8 a.tooltip-left .tooltip-arrow {
    left: 287px
}

.cssmap-750 .pl9 a {
    left: 586px;
    top: 581px
}

.cssmap-750 .pl9 a.tooltip-right .tooltip-arrow {
    right: 164px
}

.cssmap-750 .pl10 a {
    left: 640px;
    top: 206px
}

.cssmap-750 .pl10 a.tooltip-right .tooltip-arrow {
    right: 110px
}

.cssmap-750 .pl11 a {
    left: 292px;
    top: 94px
}

.cssmap-750 .pl11 a.tooltip-left .tooltip-arrow {
    left: 292px
}

.cssmap-750 .pl12 a {
    left: 365px;
    top: 530px
}

.cssmap-750 .pl13 a {
    left: 492px;
    top: 496px
}

.cssmap-750 .pl13 a.tooltip-right .tooltip-arrow {
    right: 258px
}

.cssmap-750 .pl14 a {
    left: 502px;
    top: 133px
}

.cssmap-750 .pl14 a.tooltip-right .tooltip-arrow {
    right: 248px
}

.cssmap-750 .pl15 a {
    left: 240px;
    top: 327px
}

.cssmap-750 .pl15 a.tooltip-left .tooltip-arrow {
    left: 240px
}

.cssmap-750 .pl16 a {
    left: 123px;
    top: 169px
}

.cssmap-750 .pl16 a.tooltip-left .tooltip-arrow {
    left: 123px
}

/* size: 850 */
.cssmap-850 {
    width: 850px
}

.cssmap-850 .poland, .cssmap-850 .poland .bg, .cssmap-850 .poland-cities {
    background: transparent url('default/pl-850.png') no-repeat 0 0
}

/*.cssmap-850 .poland.cssmap-blue, .cssmap-850 .poland.cssmap-blue .bg, .cssmap-850 .poland-cities.cssmap-blue {*/
/*    background-image: url('blue/pl-850.png')*/
/*}*/

/*.cssmap-850 .poland.cssmap-dark, .cssmap-850 .poland.cssmap-dark .bg, .cssmap-850 .poland-cities.cssmap-dark {*/
/*    background-image: url('dark/pl-850.png')*/
/*}*/

/*.cssmap-850 .poland.cssmap-vintage, .cssmap-850 .poland.cssmap-vintage .bg, .cssmap-850 .poland-cities.cssmap-vintage {*/
/*    background-image: url('vintage/pl-850.png')*/
/*}*/

/*.cssmap-850 .poland.cssmap-custom, .cssmap-850 .poland.cssmap-custom .bg, .cssmap-850 .poland-cities.cssmap-custom {*/
/*    background-image: url('custom/pl-850.png')*/
/*}*/

.cssmap-850 .poland, .cssmap-850 .poland-cities {
    height: 805px;
    width: 850px
}

.cssmap-850 .poland-cities {
    background-position: -860px 0
}

.cssmap-850 .poland .m {
    margin: 9992px 0 0 -7px
}

.cssmap-850 .poland .m span {
    font-size: 54px !important;
    padding: 0 1px 1px 0
}

.cssmap-850 .poland .bg {
    padding: 10px
}

.cssmap-850 .pl1.focus .bg, .cssmap-850 .pl1.active-region .bg {
    height: 220px;
    left: 66px;
    top: 421px;
    width: 241px
}

.cssmap-850 .pl1.focus .bg {
    background-position: -651px -1177px
}

.cssmap-850 .pl1.active-region .bg {
    background-position: -1751px -1177px
}

.cssmap-850 .pl2.focus .bg, .cssmap-850 .pl2.active-region .bg {
    height: 196px;
    left: 263px;
    top: 156px;
    width: 204px
}

.cssmap-850 .pl2.focus .bg {
    background-position: -873px -857px
}

.cssmap-850 .pl2.active-region .bg {
    background-position: -1973px -857px
}

.cssmap-850 .pl3.focus .bg, .cssmap-850 .pl3.active-region .bg {
    height: 263px;
    left: 617px;
    top: 357px;
    width: 203px
}

.cssmap-850 .pl3.focus .bg {
    background-position: -9px -1404px
}

.cssmap-850 .pl3.active-region .bg {
    background-position: -1109px -1404px
}

.cssmap-850 .pl4.focus .bg, .cssmap-850 .pl4.active-region .bg {
    height: 232px;
    left: 43px;
    top: 245px;
    width: 153px
}

.cssmap-850 .pl4.focus .bg {
    background-position: -922px -1092px
}

.cssmap-850 .pl4.active-region .bg {
    background-position: -2022px -1092px
}

.cssmap-850 .pl5.focus .bg, .cssmap-850 .pl5.active-region .bg {
    height: 203px;
    left: 330px;
    top: 342px;
    width: 210px
}

.cssmap-850 .pl5.focus .bg {
    background-position: -520px -1684px
}

.cssmap-850 .pl5.active-region .bg {
    background-position: -1620px -1684px
}

.cssmap-850 .pl6.focus .bg, .cssmap-850 .pl6.active-region .bg {
    height: 170px;
    left: 412px;
    top: 586px;
    width: 188px
}

.cssmap-850 .pl6.focus .bg {
    background-position: -308px -1214px
}

.cssmap-850 .pl6.active-region .bg {
    background-position: -1408px -1214px
}

.cssmap-850 .pl7.focus .bg, .cssmap-850 .pl7.active-region .bg {
    height: 326px;
    left: 425px;
    top: 197px;
    width: 314px
}

.cssmap-850 .pl7.focus .bg {
    background-position: -308px -858px
}

.cssmap-850 .pl7.active-region .bg {
    background-position: -1408px -858px
}

.cssmap-850 .pl8.focus .bg, .cssmap-850 .pl8.active-region .bg {
    height: 158px;
    left: 235px;
    top: 500px;
    width: 145px
}

.cssmap-850 .pl8.focus .bg {
    background-position: -9px -1697px
}

.cssmap-850 .pl8.active-region .bg {
    background-position: -1109px -1697px
}

.cssmap-850 .pl9.focus .bg, .cssmap-850 .pl9.active-region .bg {
    height: 229px;
    left: 578px;
    top: 548px;
    width: 196px
}

.cssmap-850 .pl9.focus .bg {
    background-position: -503px -1426px
}

.cssmap-850 .pl9.active-region .bg {
    background-position: -1603px -1426px
}

.cssmap-850 .pl10.focus .bg, .cssmap-850 .pl10.active-region .bg {
    height: 290px;
    left: 615px;
    top: 69px;
    width: 192px
}

.cssmap-850 .pl10.focus .bg {
    background-position: -652px -858px
}

.cssmap-850 .pl10.active-region .bg {
    background-position: -1752px -858px
}

.cssmap-850 .pl11.focus .bg, .cssmap-850 .pl11.active-region .bg {
    height: 186px;
    left: 219px;
    top: 10px;
    width: 239px
}

.cssmap-850 .pl11.focus .bg {
    background-position: -242px -1710px
}

.cssmap-850 .pl11.active-region .bg {
    background-position: -1342px -1710px
}

.cssmap-850 .pl12.focus .bg, .cssmap-850 .pl12.active-region .bg {
    height: 215px;
    left: 327px;
    top: 513px;
    width: 157px
}

.cssmap-850 .pl12.focus .bg {
    background-position: -790px -1654px
}

.cssmap-850 .pl12.active-region .bg {
    background-position: -1890px -1654px
}

.cssmap-850 .pl13.focus .bg, .cssmap-850 .pl13.active-region .bg {
    height: 148px;
    left: 462px;
    top: 481px;
    width: 176px
}

.cssmap-850 .pl13.focus .bg {
    background-position: -10px -1226px
}

.cssmap-850 .pl13.active-region .bg {
    background-position: -1110px -1226px
}

.cssmap-850 .pl14.focus .bg, .cssmap-850 .pl14.active-region .bg {
    height: 179px;
    left: 415px;
    top: 65px;
    width: 298px
}

.cssmap-850 .pl14.focus .bg {
    background-position: -737px -1426px
}

.cssmap-850 .pl14.active-region .bg {
    background-position: -1837px -1426px
}

.cssmap-850 .pl15.focus .bg, .cssmap-850 .pl15.active-region .bg {
    height: 339px;
    left: 144px;
    top: 173px;
    width: 269px
}

.cssmap-850 .pl15.focus .bg {
    background-position: -10px -857px
}

.cssmap-850 .pl15.active-region .bg {
    background-position: -1110px -857px
}

.cssmap-850 .pl16.focus .bg, .cssmap-850 .pl16.active-region .bg {
    height: 266px;
    left: 10px;
    top: 47px;
    width: 232px
}

.cssmap-850 .pl16.focus .bg {
    background-position: -241px -1414px
}

.cssmap-850 .pl16.active-region .bg {
    background-position: -1341px -1414px
}

.cssmap-850 .pl1 a {
    left: 206px;
    top: 516px
}

.cssmap-850 .pl1 a.tooltip-left .tooltip-arrow {
    left: 206px
}

.cssmap-850 .pl2 a {
    left: 375px;
    top: 263px
}

.cssmap-850 .pl3 a {
    left: 720px;
    top: 499px
}

.cssmap-850 .pl3 a.tooltip-right .tooltip-arrow {
    right: 130px
}

.cssmap-850 .pl4 a {
    left: 113px;
    top: 377px
}

.cssmap-850 .pl4 a.tooltip-left .tooltip-arrow {
    left: 113px
}

.cssmap-850 .pl5 a {
    left: 450px;
    top: 454px
}

.cssmap-850 .pl6 a {
    left: 516px;
    top: 680px
}

.cssmap-850 .pl7 a {
    left: 587px;
    top: 343px
}

.cssmap-850 .pl7 a.tooltip-right .tooltip-arrow {
    right: 263px
}

.cssmap-850 .pl8 a {
    left: 325px;
    top: 574px
}

.cssmap-850 .pl9 a {
    left: 668px;
    top: 663px
}

.cssmap-850 .pl9 a.tooltip-right .tooltip-arrow {
    right: 182px
}

.cssmap-850 .pl10 a {
    left: 730px;
    top: 232px
}

.cssmap-850 .pl10 a.tooltip-right .tooltip-arrow {
    right: 120px
}

.cssmap-850 .pl11 a {
    left: 330px;
    top: 103px
}

.cssmap-850 .pl12 a {
    left: 414px;
    top: 604px
}

.cssmap-850 .pl13 a {
    left: 560px;
    top: 565px
}

.cssmap-850 .pl13 a.tooltip-right .tooltip-arrow {
    right: 290px
}

.cssmap-850 .pl14 a {
    left: 572px;
    top: 148px
}

.cssmap-850 .pl14 a.tooltip-right .tooltip-arrow {
    right: 278px
}

.cssmap-850 .pl15 a {
    left: 271px;
    top: 371px
}

.cssmap-850 .pl15 a.tooltip-left .tooltip-arrow {
    left: 271px
}

.cssmap-850 .pl16 a {
    left: 136px;
    top: 190px
}

.cssmap-850 .pl16 a.tooltip-left .tooltip-arrow {
    left: 136px
}

/* size: 960 */
.cssmap-960 {
    width: 960px
}

.cssmap-960 .poland, .cssmap-960 .poland .bg, .cssmap-960 .poland-cities {
    background: transparent url('default/pl-960.png') no-repeat 0 0
}

/*.cssmap-960 .poland.cssmap-blue, .cssmap-960 .poland.cssmap-blue .bg, .cssmap-960 .poland-cities.cssmap-blue {*/
/*    background-image: url('blue/pl-960.png')*/
/*}*/

/*.cssmap-960 .poland.cssmap-dark, .cssmap-960 .poland.cssmap-dark .bg, .cssmap-960 .poland-cities.cssmap-dark {*/
/*    background-image: url('dark/pl-960.png')*/
/*}*/

/*.cssmap-960 .poland.cssmap-vintage, .cssmap-960 .poland.cssmap-vintage .bg, .cssmap-960 .poland-cities.cssmap-vintage {*/
/*    background-image: url('vintage/pl-960.png')*/
/*}*/

/*.cssmap-960 .poland.cssmap-custom, .cssmap-960 .poland.cssmap-custom .bg, .cssmap-960 .poland-cities.cssmap-custom {*/
/*    background-image: url('custom/pl-960.png')*/
/*}*/

.cssmap-960 .poland, .cssmap-960 .poland-cities {
    height: 910px;
    width: 960px
}

.cssmap-960 .poland-cities {
    background-position: -970px 0
}

.cssmap-960 .poland .m {
    margin: 9999px 0 0 0
}

.cssmap-960 .poland .m span {
    font-size: 60px !important
}

.cssmap-960 .poland .bg {
    padding: 10px
}

.cssmap-960 .pl1.focus .bg, .cssmap-960 .pl1.active-region .bg {
    height: 244px;
    left: 83px;
    top: 475px;
    width: 268px
}

.cssmap-960 .pl1.focus .bg {
    background-position: -716px -1323px
}

.cssmap-960 .pl1.active-region .bg {
    background-position: -1937px -1323px
}

.cssmap-960 .pl2.focus .bg, .cssmap-960 .pl2.active-region .bg {
    height: 217px;
    left: 301px;
    top: 181px;
    width: 227px
}

.cssmap-960 .pl2.focus .bg {
    background-position: -969px -972px
}

.cssmap-960 .pl2.active-region .bg {
    background-position: -2190px -972px
}

.cssmap-960 .pl3.focus .bg, .cssmap-960 .pl3.active-region .bg {
    height: 292px;
    left: 695px;
    top: 405px;
    width: 225px
}

.cssmap-960 .pl3.focus .bg {
    background-position: -9px -1583px
}

.cssmap-960 .pl3.active-region .bg {
    background-position: -1230px -1583px
}

.cssmap-960 .pl4.focus .bg, .cssmap-960 .pl4.active-region .bg {
    height: 258px;
    left: 56px;
    top: 281px;
    width: 170px
}

.cssmap-960 .pl4.focus .bg {
    background-position: -1014px -1221px
}

.cssmap-960 .pl4.active-region .bg {
    background-position: -2235px -1221px
}

.cssmap-960 .pl5.focus .bg, .cssmap-960 .pl5.active-region .bg {
    height: 226px;
    left: 376px;
    top: 388px;
    width: 233px
}

.cssmap-960 .pl5.focus .bg {
    background-position: -568px -1880px
}

.cssmap-960 .pl5.active-region .bg {
    background-position: -1789px -1880px
}

.cssmap-960 .pl6.focus .bg, .cssmap-960 .pl6.active-region .bg {
    height: 188px;
    left: 467px;
    top: 659px;
    width: 209px
}

.cssmap-960 .pl6.focus .bg {
    background-position: -338px -1365px
}

.cssmap-960 .pl6.active-region .bg {
    background-position: -1559px -1365px
}

.cssmap-960 .pl7.focus .bg, .cssmap-960 .pl7.active-region .bg {
    height: 362px;
    left: 482px;
    top: 227px;
    width: 349px
}

.cssmap-960 .pl7.focus .bg {
    background-position: -338px -973px
}

.cssmap-960 .pl7.active-region .bg {
    background-position: -1559px -973px
}

.cssmap-960 .pl8.focus .bg, .cssmap-960 .pl8.active-region .bg {
    height: 176px;
    left: 271px;
    top: 563px;
    width: 161px
}

.cssmap-960 .pl8.focus .bg {
    background-position: -9px -1907px
}

.cssmap-960 .pl8.active-region .bg {
    background-position: -1230px -1907px
}

.cssmap-960 .pl9.focus .bg, .cssmap-960 .pl9.active-region .bg {
    height: 254px;
    left: 651px;
    top: 617px;
    width: 217px
}

.cssmap-960 .pl9.focus .bg {
    background-position: -552px -1597px
}

.cssmap-960 .pl9.active-region .bg {
    background-position: -1773px -1597px
}

.cssmap-960 .pl10.focus .bg, .cssmap-960 .pl10.active-region .bg {
    height: 322px;
    left: 692px;
    top: 84px;
    width: 213px
}

.cssmap-960 .pl10.focus .bg {
    background-position: -716px -972px
}

.cssmap-960 .pl10.active-region .bg {
    background-position: -1937px -972px
}

.cssmap-960 .pl11.focus .bg, .cssmap-960 .pl11.active-region .bg {
    height: 206px;
    left: 252px;
    top: 19px;
    width: 265px
}

.cssmap-960 .pl11.focus .bg {
    background-position: -263px -1907px
}

.cssmap-960 .pl11.active-region .bg {
    background-position: -1484px -1907px
}

.cssmap-960 .pl12.focus .bg, .cssmap-960 .pl12.active-region .bg {
    height: 239px;
    left: 372px;
    top: 578px;
    width: 175px
}

.cssmap-960 .pl12.focus .bg {
    background-position: -831px -1845px
}

.cssmap-960 .pl12.active-region .bg {
    background-position: -2052px -1845px
}

.cssmap-960 .pl13.focus .bg, .cssmap-960 .pl13.active-region .bg {
    height: 165px;
    left: 522px;
    top: 542px;
    width: 195px
}

.cssmap-960 .pl13.focus .bg {
    background-position: -9px -1388px
}

.cssmap-960 .pl13.active-region .bg {
    background-position: -1230px -1388px
}

.cssmap-960 .pl14.focus .bg, .cssmap-960 .pl14.active-region .bg {
    height: 199px;
    left: 470px;
    top: 80px;
    width: 331px
}

.cssmap-960 .pl14.focus .bg {
    background-position: -808px -1607px
}

.cssmap-960 .pl14.active-region .bg {
    background-position: -2029px -1607px
}

.cssmap-960 .pl15.focus .bg, .cssmap-960 .pl15.active-region .bg {
    height: 377px;
    left: 169px;
    top: 200px;
    width: 299px
}

.cssmap-960 .pl15.focus .bg {
    background-position: -9px -972px
}

.cssmap-960 .pl15.active-region .bg {
    background-position: -1230px -972px
}

.cssmap-960 .pl16.focus .bg, .cssmap-960 .pl16.active-region .bg {
    height: 295px;
    left: 20px;
    top: 60px;
    width: 258px
}

.cssmap-960 .pl16.focus .bg {
    background-position: -264px -1582px
}

.cssmap-960 .pl16.active-region .bg {
    background-position: -1485px -1582px
}

.cssmap-960 .pl1 a {
    left: 237px;
    top: 580px
}

.cssmap-960 .pl1 a.tooltip-left .tooltip-arrow {
    left: 237px
}

.cssmap-960 .pl2 a {
    left: 425px;
    top: 300px
}

.cssmap-960 .pl3 a {
    left: 808px;
    top: 561px
}

.cssmap-960 .pl3 a.tooltip-right .tooltip-arrow {
    right: 152px
}

.cssmap-960 .pl4 a {
    left: 134px;
    top: 426px
}

.cssmap-960 .pl4 a.tooltip-left .tooltip-arrow {
    left: 134px
}

.cssmap-960 .pl5 a {
    left: 508px;
    top: 511px
}

.cssmap-960 .pl6 a {
    left: 582px;
    top: 763px
}

.cssmap-960 .pl7 a {
    left: 661px;
    top: 388px
}

.cssmap-960 .pl7 a.tooltip-right .tooltip-arrow {
    right: 299px
}

.cssmap-960 .pl8 a {
    left: 369px;
    top: 645px
}

.cssmap-960 .pl9 a {
    left: 750px;
    top: 744px
}

.cssmap-960 .pl9 a.tooltip-right .tooltip-arrow {
    right: 210px
}

.cssmap-960 .pl10 a {
    left: 819px;
    top: 265px
}

.cssmap-960 .pl10 a.tooltip-right .tooltip-arrow {
    right: 141px
}

.cssmap-960 .pl11 a {
    left: 375px;
    top: 122px
}

.cssmap-960 .pl12 a {
    left: 468px;
    top: 678px
}

.cssmap-960 .pl13 a {
    left: 630px;
    top: 635px
}

.cssmap-960 .pl14 a {
    left: 644px;
    top: 172px
}

.cssmap-960 .pl14 a.tooltip-right .tooltip-arrow {
    right: 316px
}

.cssmap-960 .pl15 a {
    left: 309px;
    top: 419px
}

.cssmap-960 .pl16 a {
    left: 159px;
    top: 218px
}

.cssmap-960 .pl16 a.tooltip-left .tooltip-arrow {
    left: 159px
}

/* --------------------------------------------------------
end of the maps */